import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TableHeaderColumnComponent } from '@epsilon/core-ui';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableFilterService } from '../audience-overview/overview-table/table-filter.service';

@UntilDestroy()
@Component({
  selector: 'lib-audience-activation-schedules',
  templateUrl: './audience-activation-schedules.component.html',
  styleUrls: ['./audience-activation-schedules.component.sass']
})
export class AudienceActivationSchedulesComponent {
  scrollSortOption = 'lastModifiedDate';
  scrollSortDirection: 'ASC' | 'DESC' = 'DESC';
  formGroup: UntypedFormGroup;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    protected tableFilterService: TableFilterService
  ) {
    this.formGroup = tableFilterService.formGroup;
  }

  public sortAscDesc(option: string | string[]): string | undefined {
    return this.scrollSortOption === option || option.includes(this.scrollSortOption)
      ? this.scrollSortDirection === 'ASC'
        ? 'ascending'
        : 'descending'
      : undefined;
  }

  public scrollSort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
  }

}
