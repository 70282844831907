<div class="Core-Site">
  <coreui-site-header
    [primaryTpl]="appTitle"
    [secondaryTpl]="userActionsDropdown"
    *ngIf="!hideHeader"
  >
    <ng-template #appTitle>
      <div class="Core-SiteHeader-contentLeft">
        <div
          [className]="'Core-SiteHeader-title appendEnv env-color-' + domainName"
        >
          CORE ATTRIBUTE BUILDER
          <span *ngIf="domainName !== 'prod'"> - {{ domainName }}</span>
        </div>
      </div>
    </ng-template>
    <ng-template #userActionsDropdown>
      <coreui-toggle [checkedLabel]="checkedLabel" [uncheckedLabel]="uncheckedLabel"
        [backgroundColor]="backgroundColor"
        [enableHammerIcon]="false"
        (valueChange)="onThemeToggleChange($event)">
        <ng-template #uncheckedLabel><span class="uncheckedLabel">Light</span></ng-template>
        <ng-template #checkedLabel><span class="checkedLabel">Dark</span></ng-template>
      </coreui-toggle>
      <div class="contextInfo" *ngIf="contextInfo">
        {{ contextInfo }}
        <i
          coreUIIcon="grid"
          scale="2"
          (click)="navigateToContexts()"
          class="contextIcon"
        ></i>
      </div>
      <coreui-menu
        *ngIf="!onLoginScreen"
        [buttonLabel]="userFullName$ | async"
        menuButtonStyle="link"
        verticalPosition="bottom"
        iconAlignment="right"
      >
        <coreui-menu-section>
          <button type="button" coreUIMenuItem (click)="updateMetadataModal.show();" *ngIf="hasAdminAccess(userDetails) && contextId">
            Update Metadata
          </button>
          <button type="button" coreUIMenuItem  *ngIf="hasAdminAccess(userDetails) && contextId" (click)="navigateToAdmin()">
            Admin
          </button>
          <button type="button" coreUIMenuItem (click)="logout()">
            Log out
          </button>
        </coreui-menu-section>
      </coreui-menu>
    </ng-template>
  </coreui-site-header>
  
  <div class="Core-SiteContent" *ngIf="!isCabWrapperRoute()">
    <main class="Core-SiteContent-main">
      <router-outlet></router-outlet>
    </main>
  </div>

  <router-outlet *ngIf="isCabWrapperRoute()"></router-outlet>
  
  <coreui-modal modalWidth="30" #updateMetadataModal>
    <ng-template #titleTemplate>
       Update Metadata
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
      <button type="button" (click)="updateMetadataModal.hide()" coreUIButton="icon-large">
        <i coreUIIcon="close"></i>
      </button>
    </ng-template>
    <ng-template #contentTemplate>
      <p>{{contextInfo}}</p>
      <p>
        Pulls the latest definition from the backend to update attributes in the UI. Admin only function.
        <br /><br />
        <span *ngIf="latestMetadataSyncInfo?.completedAt">Last Updated: {{ latestMetadataSyncInfo?.completedAt | date: "yyyy/MM/dd"}}, {{showLocalTime()}}</span>
        <span *ngIf="(latestMetadataSyncInfo?.status === 'IN_PROGRESS' || errorMessage !== '')" class="errorState">
          {{errorMessage || 'Metadata syncc inprocess. Please try again later.'}}
        </span>
      </p>
    </ng-template>
    <ng-template #buttonsTemplate>
      <button coreUIButton="secondary" (click)="updateMetadataModal.hide()">
        Cancel
      </button>
      <button coreUIButton="primary" (click)="updateMetaData()" [disabled]="latestMetadataSyncInfo && latestMetadataSyncInfo?.status === 'IN_PROGRESS'">
          Update
      </button>
    </ng-template>
  </coreui-modal>
</div>
