import {  NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe, AsyncPipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgLetModule } from 'ng-let';

import { CoreActivationModule } from '@epsilon-ams/ams-core-activation';
import { MappingModule } from '@epsilon-cdp/connector-lib/mapping';
import { CoreUIModule } from '@epsilon/core-ui';
import { NavTreeModule } from '@epsilon/pcbc-nav-tree';

import { SharedModule } from './modules/shared/shared.module';
import { cabRoutes } from './cab.routing';
import { metaReducers, reducers } from './reducers';

import { CabService } from './cab.service';
import { LocalStorageService } from './local-storage.service';
import { SchedulesService } from './audience-overview/details/schedules/schedules.service';
import { TableFilterService } from './audience-overview/overview-table/table-filter.service';
import { AudienceService } from './audience/audience.service';
import { AudienceBuilderService } from './audience-builder/audience-builder.service';
import { ContextService } from './context/context.service';
import { CampaignExtractService } from './campaign-extract/campaign-extract.service';
import { DataUniverseService } from './data-universe/data-universe.service';
import { UserService } from './user/user.service';
import { DragDropService } from './services/drag-drop.service';
import { CountsService } from './counts/counts.service';
import { UrlService } from './services/url.service';
import { TenantService } from './tenant/tenant.service';
import { AdminService } from './admin/admin.service';
import { MetadataService } from './metadata/metadata.service';
import { PickerService } from './picker/picker.service';
import { DetailsModalService } from './services/details-modal.service';
import { LoadingService } from './services/loading.service';
import { AuthInterceptor } from './authorization/auth.interceptor';

import { ThrottleClickDirective } from './shared/directives/throttle-click.directive';
import { DisableDragDirective } from './shared/directives/disable-drag.directive';
import { OrderByPipe } from './audience-overview/overview-table/table-orderby.pipe';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { HighlighterPipe } from './picker/highlighter.pipe';

import { CabComponent } from './cab.component';
import { BuilderFormComponent } from './builder-form/builder-form.component';
import { AudienceBuilderComponent } from './audience-builder/audience-builder.component';
import { BuilderGroupComponent } from './builder-group/builder-group.component';
import { AudienceOverviewComponent } from './audience-overview/audience-overview.component';
import { DefinitionsTableComponent } from './audience-overview/overview-table/definitions-table/definitions-table.component';
import { CheckOverflowDirective } from './shared/directives/check-overflow.directive';
import { BuilderAttributeComponent } from './builder-attribute/builder-attribute.component';
import { AudiencesTableComponent } from './audience-overview/overview-table/audiences-table/audiences-table.component';
import { OverviewTableComponent } from './audience-overview/overview-table/overview-table.component';
import { DetailsComponent } from './audience-overview/details/details.component';
import { DetailNodeComponent } from './audience-overview/details/detail-node/detail-node.component';
import { RevisionInfoComponent } from './revision-info/revision-info.component';
import { TypeIconComponent } from './shared/components/type-icon/type-icon.component';
import { DetailsModalComponent } from './shared/components/details-modal/details-modal.component';
import { DetailBodyComponent } from './audience-overview/details/detail-body/detail-body.component';
import { ExportAudienceModalComponent } from './shared/components/export-audience-modal/export-audience-modal.component';
import { RelativeDateMenuComponent } from './shared/components/relative-date-menu/relative-date-menu.component';
import { SegmentPathToggleComponent } from './shared/components/segment-path-toggle/segment-path-toggle.component';
import { MonitorTableComponent } from './audience-overview/overview-table/monitor-table/monitor-table.component';
import { MonitorOverviewComponent } from './audience-overview/monitor/mointor-view.component';
import { BuilderAggregateComponent } from './builder-aggregate/builder-aggregate.component';
import { ScheduleAudienceModalComponent } from './shared/components/schedule-cron-modal/schedule-audience-modal.component';
import { SchedulesOverviewComponent } from './audience-overview/details/schedules/schedules-overview.component';
import { PickerComponent } from './picker/picker.component';
import { OverviewHeaderComponent } from './audience-overview/overview-table/overview-header/overview-header.component';
import { ScheduleTableComponent } from './audience-overview/overview-table/schedule-table/schedule-table.component';
import { DatasetTableComponent } from './admin/overview/dataset-table/dataset-table.component';
import { AdminComponent } from './admin/admin.component';
import { AddTableComponent} from './admin/dataset/add-tables/add-table.component';
import { DatasetComponent} from './admin/dataset/dataset.component';
import { DatasetSyncModalComponent } from './admin/dataset/dataset-sync-modal/dataset-sync-modal.component';
import { InlineEditComponent } from './inline-editor/inline-editing.component';
import { EnumerationTableComponent} from './admin/overview/enumeration-table/enumeration-table.component';
import { EnumerationComponent } from './admin/enumerations/enumeration.component';
import { ManageJoinsModalComponent } from './admin/dataset/manage-joins-modal/manage-joins-modal.component';
import { ManageEnumAttributesComponent } from './admin/enumerations/manage-enum-attributes/manage-enum-attributes.component';
import { AudienceSelectionComponent } from './audience-selection/audience-selection.component';
import { AudienceDefinitionTabFlowComponent } from './audience-definition-tab-flow/audience-definition-tab-flow.component';
import { AudienceBuilderWizardComponent } from './audience-builder-wizard/audience-builder-wizard.component';
import { AudienceBuilderDetailsComponent } from './audience-builder/audience-builder-details/audience-builder-details.component';
import { AudienceBuilderSetupComponent } from './audience-builder-setup/audience-builder-setup.component';
import { AudienceBuilderBaseComponent } from './audience-builder-base/audience-builder-base.component';
import { TenantComponent } from './tenant/tenant/tenant.component';
import { SendToComponent } from './audience-overview/overview-table/send-to/send-to.component';
import { AmsActivationWrapperComponent } from './audience-overview/overview-table/ams-activation-wrapper/ams-activation-wrapper.component';
import { PageNotFoundComponent } from './modules/core/page-not-found/page-not-found.component';
import { AmsActivationOverviewComponent } from './audience-overview/overview-table/ams-activation-overview/ams-activation-overview.component';
import { ActivityTableComponent } from './audience-overview/overview-table/activity-table/activity-table.component';

import { ENV_CONFIG_MAP } from './environments/environment-config';
import { CreateListComponent } from './audience-overview/overview-table/create-list/create-list.component';
import { SchemaFieldsAdapter } from './audience-overview/overview-table/send-to/schema-fields.adapter';
import { MappingsAdapter } from './audience-overview/overview-table/send-to/mappings.adapter';
import { DiscardChangesComponent } from './discard-changes/discard-changes.component';
import { FilterPipe } from './services/pipes/filter.pipe';
import { FieldMappingsAdapter } from './audience-overview/overview-table/send-to/field-mappings.adapter';
import { GenerateCriteriaComponent } from './audience-generate-ai/generate-criteria/generate-criteria.component';
import { ProfileSectionComponent } from './profile-section/profile-section.component';
import { AutoCompleteComponent } from './shared/components/auto-complete/auto-complete.component';
import { DetailsToggleDropdownComponent } from './shared/components/details-toggle-dropdown/details-toggle-dropdown.component';
import { AudienceActivationSchedulesComponent } from './audience-activation-schedules/audience-activation-schedules.component';
import { FeedDataTableComponent } from './audience-overview/overview-table/feed-data-table/feed-data-table.component';
import { FeedDataListService } from './feed-data-list/feed-data-list.service';
import { AssetsDefinitionsTableComponent } from './audience-overview/overview-table/assets-definitions-table/assets-definitions-table.component';
import { AssetsAudiencesTableComponent } from './audience-overview/overview-table/assets-audiences-table/assets-audiences-table.component';
import { PcmCommonModule } from '@epsilon-cdp/pcm-common-lib';
import { AudienceAIDailogComponent } from './audience-ai/audience-ai-dailog/audience-ai-dailog.component';
import { AudienceAISelectorComponent } from './audience-ai/audience-ai-selector/audience-ai-selector.component';
import { AudienceAIChatService } from './audience-ai/audience-ai-chat.service';
import { CoreUIIllustrationsModule } from '@epsilon/core-ui/illustrations';
import { MultiSelectAllComponent } from './shared/components/multi-select-all/multi-select-all.component';
import { ConfirmationPopupComponent } from './shared/components/confirmation-popup/confirmation-popup.component';

const ENVIRONMENT = 'ENVIRONMENT';
const environmentConfigFactory = (environment, appConfigService: any) => {
  const environmentConfig = ENV_CONFIG_MAP.get(environment);
  environmentConfig.productBaseUrl = appConfigService.getProductBaseUrlForCabLib();
  return environmentConfig;
};
@NgModule({
  declarations: [
    ActivityTableComponent,
    CabComponent,
    RevisionInfoComponent,
    DetailsModalComponent,
    AdminComponent,
    PickerComponent,
    HighlighterPipe,
    BuilderFormComponent,
    AudienceBuilderComponent,
    BuilderGroupComponent,
    AudienceOverviewComponent,
    AudienceAISelectorComponent,
    AudienceAIDailogComponent,
    OrderByPipe,
    SafeHtmlPipe,
    AssetsDefinitionsTableComponent,
    AssetsAudiencesTableComponent,
    DefinitionsTableComponent,
    BuilderAttributeComponent,
    CheckOverflowDirective,
    ThrottleClickDirective,
    DisableDragDirective,
    AudiencesTableComponent,
    OverviewTableComponent,
    OverviewHeaderComponent,
    DetailsComponent,
    DetailNodeComponent,
    TypeIconComponent,
    DetailBodyComponent,
    ExportAudienceModalComponent,
    RelativeDateMenuComponent,
    SegmentPathToggleComponent,
    DetailsToggleDropdownComponent,
    MonitorTableComponent,
    MonitorOverviewComponent,
    BuilderAggregateComponent,
    ScheduleAudienceModalComponent,
    SchedulesOverviewComponent,
    ScheduleTableComponent,
    DatasetTableComponent,
    AddTableComponent,
    DatasetComponent,
    DiscardChangesComponent,
    ConfirmationPopupComponent,
    DatasetSyncModalComponent,
    InlineEditComponent,
    EnumerationTableComponent,
    EnumerationComponent,
    ManageJoinsModalComponent,
    ManageEnumAttributesComponent,
    AudienceDefinitionTabFlowComponent,
    AudienceBuilderWizardComponent,
    AudienceBuilderDetailsComponent,
    AudienceBuilderSetupComponent,
    AudienceSelectionComponent,
    AudienceBuilderBaseComponent,
    TenantComponent,
    AmsActivationWrapperComponent,
    PageNotFoundComponent,
    AmsActivationOverviewComponent,
    SendToComponent,
    CreateListComponent,
    GenerateCriteriaComponent,
    FilterPipe,
    ProfileSectionComponent,
    AutoCompleteComponent,
    MultiSelectAllComponent,
    AudienceActivationSchedulesComponent,
    FeedDataTableComponent,
    AudienceAIDailogComponent,
    AudienceAISelectorComponent
  ],
  imports: [
    CommonModule,
    CoreUIModule,
    CoreUIIllustrationsModule,
    NavTreeModule,
    SharedModule,
    RouterModule.forChild(cabRoutes),
    StoreModule.forFeature('cab', reducers, { metaReducers }),
    EffectsModule.forFeature([
      ContextService,
      DataUniverseService,
      AudienceService,
      AudienceBuilderService,
      CountsService,
      UserService,
      DragDropService,
      CampaignExtractService,
      UrlService,
      AdminService,
      TenantService,
      FeedDataListService,
    ]),
    NgLetModule,
    CoreActivationModule,
    MappingModule,
    PcmCommonModule
  ],
  exports: [
    CabComponent,
    RouterModule,
    CoreUIModule,
    CheckOverflowDirective,
    ThrottleClickDirective,
    DisableDragDirective,
    RevisionInfoComponent,
    DetailsModalComponent,
    PageNotFoundComponent,
    AutoCompleteComponent
  ],
  providers: [
    OverviewHeaderComponent,
    CabService,
    LocalStorageService,
    AdminService,
    AudienceService,
    AudienceAIChatService,
    AudienceBuilderService,
    SchedulesService,
    TableFilterService,
    AuthInterceptor,
    CampaignExtractService,
    ContextService,
    CountsService,
    DataUniverseService,
    MetadataService,
    PickerService,
    DetailsModalService,
    DragDropService,
    LoadingService,
    UrlService,
    UserService,
    TenantService,
    SchemaFieldsAdapter,
    MappingsAdapter,
    FieldMappingsAdapter,
    FeedDataListService,
    {
      provide: 'environment',
      useFactory: environmentConfigFactory,
      deps: [ENVIRONMENT, 'APP_CONFIG'],
    },
    DatePipe,
    AsyncPipe
  ]
})

export class CabModule {}
