import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { AudienceAIChatRequest, AudienceAIChatResponse } from './audience-ai.model';
import { CabConstants } from '../cab.constants';

@Injectable({ providedIn: 'root'})
export class AudienceAIChatService {

  constructor(private cabHttpService: CabHttpService, private http: HttpClient) {
  }

  performChat(contextId: string, dataUniverseId: string, audienceAIChatRequest: AudienceAIChatRequest): Observable<AudienceAIChatResponse> {
    const headers = this.buildHeaders(contextId, dataUniverseId);
    const url = this.cabHttpService.apiUrl(ContextType.CAB, `/cab/proxy/gen-ai/public/audienceai/agent`);
    return this.http.post(url, audienceAIChatRequest, { headers }) as Observable<AudienceAIChatResponse>;
  }

  private buildHeaders(contextId: string, dataUniverseId: string,) {
    return {
      [CabConstants.CAB_CONTEXT_HEADER]:  contextId,
      [CabConstants.CAB_DATAUNIVERSE_ID]:  dataUniverseId
    };
  }


}
