import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { utils, auth_utils } from '@epsilon-cdp/cab-lib';
import { selectUserFullName } from '@epsilon-cdp/cab-lib';
import { MetadataService } from '@epsilon-cdp/cab-lib';
import { User } from '@epsilon-cdp/cab-lib';
import { LatestMetadataSyncInfo } from '@epsilon-cdp/cab-lib';
import { ContextService } from '@epsilon-cdp/cab-lib';
import { AppState } from '@epsilon-cdp/cab-lib';
import { selectContext } from '@epsilon-cdp/cab-lib';
import { selectUser } from '@epsilon-cdp/cab-lib';
import {Theme, ThemeColorService } from '@epsilon/core-ui';
import { ContextsService } from './contexts/contexts.service';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  domainName: string;
  onLoginScreen = this.location.path() === '/login';
  contextInfo: string;
  contextId: string;
  userFullName$ = this.store.select(selectUserFullName);
  userDetails: User;
  errorMessage = '';
  latestMetadataSyncInfo: LatestMetadataSyncInfo;
  hasAdminAccess = utils.hasAdminAccess;
  @ViewChild(('updateMetadataModal'))
  public updateMetadataModal;
  hideHeader: boolean;
  latestRoute = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private location: Location,
    private metadataService: MetadataService,
    private contextService: ContextService,
    private themeColorService: ThemeColorService,
    private contextsService: ContextsService
  ) {
    this.domainName = contextsService.environmentValue('domainName');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
        this.latestRoute = event?.url;
    });
    try {
      if(window.self !== window.top) {
        contextService.hideHeader$.next(true);
        this.contextService.hideHeader = true;
      }
    } catch(err) {
      contextService.hideHeader$.next(true);
      this.contextService.hideHeader = true;
    }
    this.contextService.hideHeader$.subscribe(value => this.hideHeader = value);

    try {
      if(window.self !== window.top) {
        console.log('Href URL is --- ', window.location.href);
        contextService.hideHeader$.next(true);
        this.contextService.hideHeader = true;
      }
    } catch(err) {
      console.log('Href URL is --- ', window.location.href);
      contextService.hideHeader$.next(true);
      this.contextService.hideHeader = true;
    }
  }

  isCabWrapperRoute() {
    return this.latestRoute.includes('cab-wrapper');
  }

  logout() {
    auth_utils.logout();
    window.location.href = `${this.contextsService.environmentValue('baseApiUrl')}/cab/logout`;
  }

  ngOnInit(): void {
    this.showContextDetails();
    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.userDetails = user));
  }

  navigateToAdmin(){
    this.router.navigate([`${this.contextsService.environmentValue(utils.PRODUCT_BASE_URL_PARAM_NAME)}`, `${this.contextId}`, 'admin']);
  }

  showLocalTime(){
    return moment().format('hh:mm A');
  }

  showContextDetails() {
    this.errorMessage = '';
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if (context && !this.onLoginScreen) {
          this.contextInfo = `${context.displayName} | ${context.productType}`;
          this.contextId = context.id;
          this.latestMetadataSyncInfo = context.latestMetadataSyncInfo;
        } else {
          this.contextInfo = '';
        }
      });
  }

  updateMetaData() {
    this.errorMessage = '';
    this.metadataService.updateMetadata(this.contextId).subscribe(res => console.log(res),
    (errorResponse) => {
      if (errorResponse.error.resultStatus === 'FAILURE') {
        this.latestMetadataSyncInfo.completedAt  = '';
        this.errorMessage = errorResponse.error.errorDetails[0].errorMessage;
      }
    });
  }

  navigateToContexts() {
    this.contextId = undefined;
    this.router.navigate(['']);
  }

  onThemeToggleChange(value: boolean): void {
    this.themeColorService.setTheme(value ? Theme.Dark : Theme.Light);
  }

}
