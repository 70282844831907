import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AudienceAIChatRequest, ChatConversation, ChatMessage, AudienceAIChatResponse, UserType, AudienceAIResponseType } from '../audience-ai.model';
import { AudienceAIChatService } from '../audience-ai-chat.service';
import { ActivatedRoute } from '@angular/router';
import { CabConstants } from "../../cab.constants";
import { AudienceDefinitionGenerateAIRating } from '../../audience-builder/audience-builder.models';
import { AudienceBuilderService } from '../../audience-builder/audience-builder.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConfirmationPopupComponent } from "../../shared/components/confirmation-popup/confirmation-popup.component";
import { NotificationComponent } from "@epsilon/core-ui";
import { AudienceService } from '../../audience/audience.service';
import { AudienceDefinition } from '../../audience/audience.models';

@UntilDestroy()
@Component({
  selector: "lib-audience-ai-dailog",
  templateUrl: "./audience-ai-dailog.component.html",
  styleUrls: ["./audience-ai-dailog.component.sass"],
})
export class AudienceAIDailogComponent implements OnInit {

  isQueryRunning = false;
  human = UserType.HUMAN;
  assistant = UserType.ASSISTANT;
  audienceAIChatRequest: AudienceAIChatRequest;
  chatConversations: ChatConversation;
  audienceAIFormGroup: UntypedFormGroup;
  contextId: string;
  confirmationTitle = 'Do you want to clear the conversation?'
  customMessage = 'Are you sure you want to restart the session, you\'ll lose all the progress of the current session.';
  confirmText = 'Yes, Clear';
  cancelText = 'Go Back';
  isUserInputValid = false;
  previewData: { [key: string]: any };
  savedAudienceDefinitionId: string;
  dataUniverseId: string;

  constructor(public audienceAIChatService: AudienceAIChatService,
    public builderService: AudienceBuilderService,
    private route: ActivatedRoute, private audienceService: AudienceService) {}

  @ViewChild('chatMessages') private chatContainer!: ElementRef | undefined;
  @ViewChild('previewContent') private previewContainer!: ElementRef | undefined;
  @ViewChild(ConfirmationPopupComponent) private confirmationPopup: ConfirmationPopupComponent;
  @ViewChild('toastSuccess', { static: true }) public toastSuccess: NotificationComponent;
  @ViewChild('toastError', { static: true }) public toastError: NotificationComponent;

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    this.initialize();
    this.initiateChat();
  }

  private initialize(): void {
    this.audienceAIChatRequest = new AudienceAIChatRequest();
    this.chatConversations = new ChatConversation();
    this.audienceAIFormGroup = this.buildAudienceAIFormGroup();
  }

  private buildAudienceAIFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      chatInput: new UntypedFormControl(""),
      previewData: new UntypedFormGroup({}),
      commentMessage: new UntypedFormControl("")
    });
  }

  public initiateChat(): void {
    this.buildChatConversations(UserType.HUMAN, CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE, [], '', null);
    this.audienceAIChatRequest.input_message = CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE;
    this.performChat(this.audienceAIChatRequest);
    this.updateChatMessages(CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE, UserType.HUMAN);
  }

  public submitQuery(): void {
    if (!this.audienceAIFormGroup.get("chatInput").value || this.audienceAIFormGroup.get("chatInput").value.trim().length === 0
      || this.audienceAIFormGroup.get("chatInput").errors || this.isQueryRunning) {
      return;
    }
    const userMessage = this.audienceAIFormGroup.get("chatInput").value;
    this.buildChatConversations(UserType.HUMAN, userMessage, [], '', null);
    this.audienceAIChatRequest.input_message = userMessage;
    this.performChat(this.audienceAIChatRequest);
    this.updateChatMessages(userMessage, UserType.HUMAN);
  }

  private buildChatConversations(userType: UserType, message: string, options: string[] = [], genAIRequestId: string, query: object): void {
    const chatMessage = new ChatMessage({role: userType, content: message, options: options, genAIRequestId, query});
    this.chatConversations.chat_history.push(chatMessage);
    this.audienceAIFormGroup.get("chatInput").reset();
    this.isUserInputValid = false;
  }

  private scrollToBottom(container: ElementRef): void {
    if (this.chatContainer && this.previewContainer) {
      setTimeout(() => {
          container.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
      }, 0);
    }
  }

  private updateChatMessages(message: string, userType: UserType): void {
    const chatMessage = new ChatMessage({role: userType, content: message});
    this.audienceAIChatRequest.chat_history.push(chatMessage);
    this.scrollToBottom(this.chatContainer);
  }

  public clearChat(): void {
    this.audienceAIChatRequest = new AudienceAIChatRequest();
    this.chatConversations = new ChatConversation();
    this.audienceAIFormGroup.reset();
    this.audienceAIFormGroup.setControl("previewData", new UntypedFormGroup({}));
    this.isQueryRunning = false;
    this.initiateChat();
  }

  public selectOption(option: string, chatId: number): void {
    this.audienceAIFormGroup.get("chatInput").patchValue(option);
    if (option == "Save Definition") {
      this.saveAudienceDefinition(chatId);
    } else {
      this.submitQuery();
    }
  }

  private saveAudienceDefinition(chatId) {
    this.isQueryRunning = true;
    const userMessage = this.audienceAIFormGroup.get("chatInput").value;
    this.buildChatConversations(UserType.HUMAN, userMessage, [], '', null);
    this.scrollToBottom(this.chatContainer);
    const audience = this.buildAudienceDefinition(chatId);
    this.audienceService.saveAudienceDefinition(audience).subscribe({
      next: (result: AudienceDefinition) => {
        console.log('Audience definition saved successfully:', result);
        this.savedAudienceDefinitionId = result.id;
        this.audienceAIChatRequest.input_message = CabConstants.SAVE_SUCCESS;
        this.performChat(this.audienceAIChatRequest);
        this.updateChatMessages(CabConstants.SAVE_SUCCESS, UserType.HUMAN);
      },
      error: (err) => {
        const errorMessage = err.error.errorDetails && err.error.errorDetails.length > 0 ? err.error.errorDetails[0].errorMessage: '';
        console.error('An error occurred while saving audience definition', errorMessage);
        this.audienceAIChatRequest.input_message = CabConstants.SAVE_FAILURE;
        this.performChat(this.audienceAIChatRequest);
        this.updateChatMessages(CabConstants.SAVE_FAILURE, UserType.HUMAN);
      }
    });
  }

  private buildAudienceDefinition(chatId: number): AudienceDefinition {
    let chatMessage = this.chatConversations.chat_history[chatId];
    chatMessage = chatMessage.query != null ? chatMessage : 
    this.chatConversations.chat_history.reverse().find(chatMessage => chatMessage.query !== null);
    const audienceDefinition = new AudienceDefinition(chatMessage);
    audienceDefinition.displayName = this.previewData['Audience Name'];
    audienceDefinition.cabContextId = this.route.snapshot.paramMap.get('contextId');
    audienceDefinition.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    audienceDefinition.id = this.savedAudienceDefinitionId;
    audienceDefinition.dedupeIdentityType = 'DigitalCoreId'
    audienceDefinition.audienceAttributes = {
      "channelType": this.previewData['Channel'],
      "alternateKeyType": ""
    }
    return audienceDefinition;
  }

  private performChat(audienceAIChatRequest: AudienceAIChatRequest) {
    this.isQueryRunning = true;
    this.audienceAIChatService
      .performChat(this.contextId, this.dataUniverseId, audienceAIChatRequest)
      .subscribe((response: AudienceAIChatResponse) => {
        if (response.response_type == AudienceAIResponseType.OK) {
          const chatMessage = new ChatMessage({ role: this.assistant, content: response.llm_output });
          this.audienceAIChatRequest.chat_history.push(chatMessage);
        } else if (response.response_type == AudienceAIResponseType.ERROR) {
          this.audienceAIChatRequest.chat_history.pop();
        }
        this.scrollToBottom(this.chatContainer);
        const previewDataFormGroup = this.buildPreviewDataFormGroup(response.preview_data);
        this.scrollToBottom(this.previewContainer);
        this.audienceAIFormGroup.setControl("previewData", previewDataFormGroup);
        this.buildChatConversations(UserType.ASSISTANT, response.chat_message, response.options, response.genAIRequestId, response.query);
        this.isQueryRunning = false;
        this.previewData = response.preview_data;
      }, (error: any) => {
        console.log('Backend Error occured: %s', error.message);
        this.audienceAIChatRequest.chat_history.pop();
        if (this.audienceAIChatRequest.chat_history.length < 2) {
          const errorHandlingMessage = "Apologies, Server error occurred! Can not initiate Audience AI";
          this.buildChatConversations(UserType.ASSISTANT, errorHandlingMessage, [], "", null);
        }
        else {
          const prevChatConversation = this.chatConversations.chat_history[this.chatConversations.chat_history.length - 2];
          const errorHandlingMessage = "Apologies, Server Error Occurred! Let's Try Again \n\n" + prevChatConversation.content;
          this.buildChatConversations(UserType.ASSISTANT, errorHandlingMessage, prevChatConversation.options, prevChatConversation.genAIRequestId, null);
        }
        this.scrollToBottom(this.chatContainer);
        this.scrollToBottom(this.previewContainer);
        this.isQueryRunning = false;
      });
  }

  private buildPreviewDataFormGroup(data: any): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    for (const key in data) {
      if (data[key]) {
        formGroup.addControl(key, new UntypedFormControl({ value: data[key], disabled: false }));
      }
    }
    return formGroup;
  }

  public getTransformedText(chatMessage: string): string {
    return chatMessage?.replace(/\n/g, "<br/>") ?? "";
  }

  public getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  public selectFeedback(userRating: number, chatIndex: number): void {
    this.chatConversations.chat_history[chatIndex].feedbackRating = userRating;
    this.chatConversations.chat_history[chatIndex].isFeebackCommentGiven = false;
    if ((this.chatConversations.chat_history.length - 1) === chatIndex) {
      this.scrollToBottom(this.chatContainer);
    }
  }

  public submitFeedback(chatIndex: number): void {
    const userRating = this.chatConversations.chat_history[chatIndex].feedbackRating;
    const generateFromTextRequest: AudienceDefinitionGenerateAIRating = {
      cabContextId: this.route.snapshot.paramMap.get('contextId'),
      genAIRequestId: this.chatConversations.chat_history[chatIndex].genAIRequestId,
      userRating,
      userComments: this.audienceAIFormGroup.get("commentMessage").value
    };
    this.builderService.generateAudienceDefinitionGenAIQueryRating(generateFromTextRequest)
      .subscribe(() => {
        this.audienceAIFormGroup.get("commentMessage").reset();
        this.showToast(this.toastSuccess);
        this.chatConversations.chat_history[chatIndex].isFeebackCommentGiven = true;
      }, (error: any) => {
        console.log("Error occurred during feedback: %s", error.message);
        this.showToast(this.toastError);
      });
  }

  openConfirmationPopup(): void {
    this.confirmationPopup.launchAlertModal();
  }

  handleConfirm(): void {
    this.clearChat();
  }

  handleCancel(): void {
    return;
  }

  onChatInputChange(value: string): void {
    this.isUserInputValid = (value && value.trim().length > 0) ? true : false;
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  public isFeedbackMenuShown(genAIRequestId: string): boolean {
    return genAIRequestId ? true : false;
  }

  public isFeedbackSelected(index: number, rating: number): boolean {
    return this.chatConversations.chat_history[index].feedbackRating === rating ? true : false;
  }

  public isCommentAreaShown(index: number): boolean {
    return this.chatConversations.chat_history[index].feedbackRating !== 0
      && this.chatConversations.chat_history[index].isFeebackCommentGiven === false;
  }

}
