import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  AssetSearchFilterCondition,
  AssetSearchRequest,
  AssetSearchResponse,
  AssetService,
} from '@epsilon-cdp/pcm-common-lib';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { selectActiveDataUniverse } from '../../../data-universe/data-universe.reducer';
import {
  auditTime,
  BehaviorSubject,
  filter,
  pairwise,
  startWith,
  take,
} from 'rxjs';
import {
  AUDIENCE_TXT,
  BE_STATUS_COMPLETE,
  BE_STATUS_ERROR,
  BE_STATUS_PROCESSING,
  BE_STATUS_SUCCESS,
  UI_STATUS_COMPLETE,
  UI_STATUS_ERROR,
  UI_STATUS_PROCESSING,
  isDefined,
} from '../../../utils/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { selectTenantContext } from '../../../tenant/tenant.reducer';
import { selectContext } from '../../../context/context.reducer';
import { UtilsService } from '../../../utils/utilservice';
import { ActivatedRoute, Router } from '@angular/router';
import { AudienceBuilderService } from '../../../audience-builder/audience-builder.service';
import { first, reject } from 'lodash-es';
import { CabConstants } from '../../../cab.constants';
import {
  Audience,
  AudienceDefinition,
} from '../../../audience/audience.models';
import { FeatureAccessService } from '../../../feature-access/feature.access.service';
import { Action } from '../../../feature-access/feature.access.action';
import { FeatureService } from '../../../utils/feature-service';
import { FEATURE_EXPORT_CAMPAIGN_EXTRACTS } from '../../../utils/feature-utils';
import { ModalComponent } from '@epsilon/core-ui';
import {
  DestroyAudience,
  DestroyAudiences,
} from '../../../audience/audience.actions';
import { fetchOutcome } from '../../../utils/fetch-state';
import { Actions } from '@ngrx/effects';
import { LoadingService } from '../../../services/loading.service';
import { TableFilterService } from '../table-filter.service';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'lib-assets-audiences-table',
  templateUrl: './assets-audiences-table.component.html',
  styleUrls: ['./assets-audiences-table.component.sass'],
})
export class AssetsAudiencesTableComponent implements OnInit {
  @ViewChild('deleteActionModal') public deleteActionModal;
  @ViewChild('deleteActionOneModal') public deleteActionOneModal;
  @ViewChild('exportSuccess', { static: true }) public exportSuccess;
  @ViewChild('exportError', { static: true }) public exportError;
  @ViewChild('destroySuccess', { static: true }) public destroySuccess;
  @ViewChild('destroyError', { static: true }) public destroyError;
  formGroup: UntypedFormGroup;
  scrollSortOption = 'lastModifiedDate';
  scrollSortDirection: 'ASC' | 'DESC' = 'DESC';
  PAGE_LIMIT = 25;
  searchPayload: AssetSearchRequest = {
    searchName: '',
    searchId: '',
    filter: [
      {
        attribute: 'type',
        values: ['AUDIENCE_LIST'],
        condition: AssetSearchFilterCondition.IN,
      },
    ],
    limit: this.PAGE_LIMIT,
    offset: 0,
    sort: ['-data.lastModifiedDate'],
  };
  filteredAudience = [];
  hasMore = false;
  productType: string;
  contextId: string;
  dropdownListActions = [];
  isCampaignExtractEnabled: boolean;
  isContextExportDisabled: boolean;
  actionInProgress$ = new BehaviorSubject<{
    type: string;
    audiences: Audience[];
  } | null>(null);
  extractFromListEnabled: boolean;
  enableADHListSendTo: boolean;
  hasAccess: boolean;
  destroyErrorHeader: string;
  successMessage: string;
  destroyErrorMessage: string;
  nestedDefs = '';
  isLoader = false;
  initialLoad = true;
  dataUniverseId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    public assetService: AssetService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private builderService: AudienceBuilderService,
    private featureAccessService: FeatureAccessService,
    private featureService: FeatureService,
    private actions$: Actions,
    protected loadingService: LoadingService,
    protected tableFilterService: TableFilterService
  ) {
    this.formGroup = tableFilterService.formGroup;
    this.tableFilterService.searchSavedFilter$.subscribe(data => {
      this.onSearchSavedFilter(data);
    })
  }

  onSearchSavedFilter(selectedFilters) {
    this.initialLoad = true;
    if(selectedFilters === 'reset') {
      this.resetSearchPayload();
      this.tableFilterService.statuses$.next([]);
      this.tableFilterService.tags$.next([]);
      this.formGroup
      .get('search').setValue('');
    } else {
      this.searchPayload.filter = selectedFilters?.filter;
      this.searchPayload.sort = selectedFilters?.sort;
      this.searchPayload.searchId = selectedFilters?.searchId;
      const searchText = this.searchPayload.filter?.filter(
        filter => filter.condition === AssetSearchFilterCondition.ILIKE
      )[0]?.values[0] || '';
      this.formGroup
      .get('search').setValue(searchText);
      const tags = this.searchPayload.filter?.filter(
        filter => filter.attribute === 'tags.tag'
      )[0]?.values || [];
      this.tableFilterService.tags$.next(tags);
      const statuses = this.searchPayload.filter?.filter(
        filter => filter.attribute === 'data.status'
      )[0]?.values || [];
      const audienceListStatus = [];
      statuses.forEach(status => {
        if (status === BE_STATUS_COMPLETE || status === BE_STATUS_SUCCESS) {
          if (!audienceListStatus.includes(UI_STATUS_COMPLETE)) {
            audienceListStatus.push(UI_STATUS_COMPLETE);
          }
        }
        else if (status === BE_STATUS_ERROR) {
          audienceListStatus.push(UI_STATUS_ERROR);
        }
        else {
          audienceListStatus.push(UI_STATUS_PROCESSING);
        }
      });
      this.tableFilterService.statuses$.next(audienceListStatus);
    }
    this.filteredAudience = [];
    setTimeout(() => {
      this.search();
    }, 300);
  }

  ngOnInit(): void {
    this.hasAccess = this.featureAccessService.hasAccess(Action.AUDIENCE_VIEW);
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.extractFromListEnabled =
          context?.metadata['extract.from.list.enabled'];
        this.productType = context?.productType;
        if (context && !context.exportAudience) {
          this.isContextExportDisabled = true;
        }
        if (context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.dropdownListActions = this.dropdownListActions.filter(
            (item) => item.display === 'Delete'
          );
        }
        if (this.productType) {
          this.isCampaignExtractEnabled = this.featureService.isFeatureEnabled(
            FEATURE_EXPORT_CAMPAIGN_EXTRACTS
          );
        }
        this.enableADHListSendTo = context?.enableADHListSendTo;
      });
    this.store
      .select(selectTenantContext)
      .pipe(untilDestroyed(this))
      .subscribe((contextId) => {
        if (contextId) {
          this.contextId = contextId;
        }
      });
    this.store
      .select(selectActiveDataUniverse)
      .pipe(untilDestroyed(this), filter(isDefined))
      .subscribe((dataUniverse: DataUniverse) => {
        if (dataUniverse.id !== this.dataUniverseId) {
          this.dataUniverseId = dataUniverse.id;
          this.resetSearchPayload();
          if (!this.initialLoad) {
            this.search();
          }
        }
      });
    this.formGroup
      .get('search')
      .valueChanges.pipe(auditTime(250), startWith(''), pairwise(), untilDestroyed(this))
      .subscribe(([prevSearchText,searchText]) => {
        if(prevSearchText === searchText){
          return;
        }
        const currentFilter = this.searchPayload?.filter || [];
        this.resetSearchPayload();
        this.searchPayload.filter = currentFilter;
        this.searchPayload.filter = this.searchPayload.filter?.filter(
          (val) => val?.attribute !== 'name'
        );
        this.searchPayload.filter?.push(
          ...(searchText
            ? [
                {
                  attribute: 'name',
                  values: [searchText],
                  condition: AssetSearchFilterCondition.ILIKE,
                },
              ]
            : [])
        );

        if (!this.initialLoad) {
          this.isLoading();
        }
      });

    this.tableFilterService.dateTypes$
      .pipe(untilDestroyed(this))
      .subscribe((dateType: any) => {
        const currentFilter = this.searchPayload?.filter || [];
        this.resetSearchPayload();
        this.searchPayload.filter = currentFilter;
        this.searchPayload.filter = this.searchPayload.filter?.filter(
          (val) => val?.attribute !== 'data.lastModifiedDate'
        );
        const dateObj = {
          today: 0,
          yesterday: 1,
          recent3Days: 2,
          recent7Days: 6,
          recent30Days: 29,
        };
        let fromDateTime;
        let toDateTime;
        if (dateType?.length > 0 && dateObj.hasOwnProperty(dateType)) {
          const filterDate: number = dateObj[dateType];
          const format = 'YYYY-MM-DDTHH:mm:ss';
          fromDateTime = moment
            .utc(
              moment()
                .subtract(filterDate, 'days')
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
            )
            .format(format);
          if (dateType === 'yesterday') {
            toDateTime = moment
              .utc(
                moment()
                  .subtract(filterDate, 'days')
                  .hours(23)
                  .minutes(59)
                  .seconds(59)
                  .milliseconds(999)
              )
              .format(format);
          } else {
            toDateTime = moment
              .utc(moment().hours(23).minutes(59).seconds(59).milliseconds(999))
              .format(format);
          }
        }

        this.searchPayload.filter?.push(
          ...(fromDateTime && toDateTime
            ? [
                {
                  attribute: 'data.lastModifiedDate',
                  values: [toDateTime],
                  condition: AssetSearchFilterCondition.LTE,
                },
                {
                  attribute: 'data.lastModifiedDate',
                  values: [fromDateTime],
                  condition: AssetSearchFilterCondition.GTE,
                },
              ]
            : [])
        );
        if (!this.initialLoad) {
          this.search();
        }
      });

    this.tableFilterService.statuses$
      .pipe(untilDestroyed(this))
      .subscribe((statuses: any[]) => {
        const currentFilter = this.searchPayload?.filter || [];
        this.resetSearchPayload();
        this.searchPayload.filter = currentFilter;
        this.searchPayload.filter = this.searchPayload.filter?.filter(
          (val) => val?.attribute !== 'data.status'
        );

        const audienceListStatusBE = [];
        statuses.forEach(status => {
        if (status === UI_STATUS_COMPLETE) {
          audienceListStatusBE.push(BE_STATUS_COMPLETE);
          audienceListStatusBE.push(BE_STATUS_SUCCESS);
        } else if (status === UI_STATUS_ERROR) {
          audienceListStatusBE.push(BE_STATUS_ERROR);
        } else {
          audienceListStatusBE.push(BE_STATUS_PROCESSING);
        }
      });
        this.searchPayload.filter.push(
          ...(statuses.length
            ? [
                {
                  attribute: 'data.status',
                  values: audienceListStatusBE,
                  condition: AssetSearchFilterCondition.IN,
                },
              ]
            : [])
        );
        if (!this.initialLoad) {
          this.search();
        }
      });

    this.tagsFilterChange();
  }

  resetSearchPayload() {
    this.searchPayload = {
      searchName: '',
      searchId: '',
      filter: [
        {
          attribute: 'type',
          values: ['AUDIENCE_LIST'],
          condition: AssetSearchFilterCondition.IN,
        },
      ],
      limit: this.PAGE_LIMIT,
      offset: 0,
      sort: ['-data.lastModifiedDate'],
    };
    this.filteredAudience = [];
  }

  tagsFilterChange() {
    this.tableFilterService.tags$
      .pipe(untilDestroyed(this))
      .subscribe((tags: any[]) => {
        const currentFilter = this.searchPayload?.filter || [];
        this.resetSearchPayload();
        this.searchPayload.filter = currentFilter;
        this.searchPayload.filter = this.searchPayload.filter?.filter(
          (val) => val?.attribute !== 'tags.tag'
        );
        this.searchPayload.filter.push(
          ...(tags?.length > 0
            ? [
                {
                  attribute: 'tags.tag',
                  values: tags,
                  condition: AssetSearchFilterCondition.IN,
                },
              ]
            : [])
        );
        if (!this.initialLoad) {
          this.search();
        }
      });
  }

  search() {
    this.isLoader = true;
    this.loadingService.isHttpRequestLoading$.next(true);
    this.assetService
      .search(this.searchPayload)
      .subscribe((res: AssetSearchResponse) => {
        this.initialLoad = false;
        const getSearchResponse = res.results
          ? this.createFilteredDefinition(res.results, this.contextId)
          : [];
        const getFilteredAudienceBasedOnSearchResponse = [
          ...this.filteredAudience,
          ...getSearchResponse,
        ];
        this.filteredAudience = getFilteredAudienceBasedOnSearchResponse;
        this.isLoader = false;
        this.hasMore = res.hasMore;
        this.searchPayload.searchId = res.searchId ?? '';
        this.loadingService.isHttpRequestLoading$.next(false);
        this.tableFilterService.searchId$.next(this.searchPayload.searchId);
      });
  }

  scrollSort(sortOption: string) {
    if (this.scrollSortOption === sortOption) {
      this.scrollSortDirection =
        this.scrollSortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.scrollSortOption = sortOption;
      this.scrollSortDirection = 'ASC';
    }
    const sort = this.scrollSortDirection === 'DESC' ? `-${sortOption}` : sortOption;
    this.searchPayload.sort = [sort];
    this.searchPayload.searchId = '';
    this.filteredAudience = [];
    this.search();
  }

  public limitReached() {
    if (this.hasMore) {
      this.searchPayload.offset += this.PAGE_LIMIT;
      this.search();
    }
  }

  sortAscDesc(option: string | string[]): string | undefined {
    return this.scrollSortOption === option ||
      (Array.isArray(option) && option.includes(this.scrollSortOption))
      ? this.scrollSortDirection === 'ASC'
        ? 'ascending'
        : 'descending'
      : undefined;
  }

  createFilteredDefinition(searchResults: any, contextId: string): AudienceDefinition[] {
    return (searchResults || []).reduce((acc, item) => {
      acc.push(new Audience(this.getFlattedDefinitions({...item, contextId})));
      return acc;
    }, []);
  }

  getFlattedDefinitions(definition): AudienceDefinition {
    const { data, ...definitionWithoutData } = definition;
    return {
      ...data,
      ...definitionWithoutData,
      id: definition.assetId,
    };
  }

  routeToViewDetails(item) {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      item.dataUniverseId,
      AUDIENCE_TXT,
      'view',
      item.id,
    ]);
  }

  getDetailUrl(audience) {
    const audienceDataUniverseId = audience?.dataUniverseId;
    const audienceAssetId = audience?.id;
    return `${this.utilsService.getProductBaseUrl(this.router, this.route)}/${
      this.contextId
    }/${audienceDataUniverseId}/${AUDIENCE_TXT}/view/${audienceAssetId}`;
  }

  public getAudienceDisplayName(
    dedupeType: string,
    channelType: string
  ): string {
    return this.builderService.getAudienceDisplayName(dedupeType, channelType);
  }
  public enabledDropdownListActions(item?) {
    this.addDropDownActionsList(item);
    return reject(this.dropdownListActions, { disabled: true });
  }

  sendToAMS(list) {
    this.router.navigate(
      [
        this.utilsService.getProductBaseUrl(this.router, this.route),
        this.contextId,
        list.dataUniverseId,
        list.id,
        'ams',
      ],
      {
        queryParams: {
          audienceId: list.audienceAttributes.amsAudienceId,
          type: 'list',
        },
      }
    );
  }

  private addDropDownActionsList(item?: AudienceDefinition) {
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_VIEW)) {
      this.dropdownListActions = [
        {
          display: 'View Details',
          onClick: (audience) => {
            this.router.navigate([
              this.utilsService.getProductBaseUrl(this.router, this.route),
              this.contextId,
              audience.dataUniverseId,
              AUDIENCE_TXT,
              'view',
              audience.id,
            ]);
          },
        },
        {
          display: 'Export',
          disabled:
            !this.isCampaignExtractEnabled || this.isContextExportDisabled,
          onClick: (audience) => {
            if (audience.status === UI_STATUS_COMPLETE) {
              this.actionInProgress$.next({
                type: 'export',
                audiences: [audience],
              });
            }
          },
        },
      ];
    }

    if (
      this.featureAccessService.hasAccess(Action.AUDIENCE_PAID_RUN) &&
      this.productType === CabConstants.DCDP_PRODUCT_NAME &&
      item?.audienceAttributes?.channelType === 'PAID'
    ) {
      this.dropdownListActions.push({
        display: 'Deliver To...',
        onClick: (list) => {
          if (list.status === UI_STATUS_COMPLETE) {
            this.sendToAMS(list);
          }
        },
        disabled:
          !this.extractFromListEnabled ||
          this.productType !== CabConstants.DCDP_PRODUCT_NAME,
      });
    }

    if (
      this.featureAccessService.hasAccess(Action.AUDIENCE_OWNED_RUN) &&
      this.productType === CabConstants.DCDP_PRODUCT_NAME &&
      item?.audienceAttributes?.channelType === 'OWNED'
    ) {
      this.dropdownListActions.push({
        display: 'Deliver To...',
        onClick: (list) => {
          if (list.status === UI_STATUS_COMPLETE) {
            if (this.enableADHListSendTo) {
              this.router.navigate(
                [
                  this.utilsService.getProductBaseUrl(this.router, this.route),
                  this.contextId,
                  list.dataUniverseId,
                  list.id,
                  'sendTo',
                ],
                { queryParams: { cabId: list.cabId, type: 'list' } }
              );
            } else {
              this.router.navigate(
                [
                  this.utilsService.getProductBaseUrl(this.router, this.route),
                  this.contextId,
                  list.dataUniverseId,
                  list.id,
                  'cab',
                  'sendTo',
                ],
                { queryParams: { cabId: list.cabId, type: 'list' } }
              );
            }
          }
        },
        disabled:
          !this.extractFromListEnabled ||
          this.productType !== CabConstants.DCDP_PRODUCT_NAME,
      });
    }

    if (this.featureAccessService.hasAccess(Action.AUDIENCE_DELETE)) {
      this.dropdownListActions.push({
        display: 'Delete',
        onClick: (audience) => {
          this.actionInProgress$.next({
            type: 'delete',
            audiences: [audience],
          });
          this.deleteActionOneModal.show();
        },
      });
    }
  }

  readDestroyAudienceResponse() {
    this.actions$.pipe(fetchOutcome(DestroyAudience.type), take(1)).subscribe(
      () => {
        this.successMessage = 'This List has successfully been deleted.';
        this.destroySuccess.show();
        this.resetSearchPayload();
        this.isLoading();
      },
      ({ error }) => {
        const nestedDefs: Array<string> = JSON.parse(error).errorDetails
          ? JSON.parse(error)
              .errorDetails[0].errorMessage.split('is referenced in ')[1]
              .split(',')
          : [];
        this.destroyErrorHeader = 'Unable to delete this Object';
        if (nestedDefs.length > 1) {
          this.destroyErrorMessage =
            'This object can’t be deleted because they’re nested in the following ' +
            nestedDefs.length +
            '  definitions or lists.';
        } else {
          this.destroyErrorMessage =
            "This object can’t be deleted because it's nested in the following definition.";
        }
        this.nestedDefs =
          JSON.parse(error).errorDetails[0].errorMessage.split(
            'is referenced in '
          )[1];
        this.destroyError.show();
        this.resetSearchPayload();
        this.isLoading();
      }
    );
  }

  public deleteAction(modal: ModalComponent) {
    const actionDetails = this.actionInProgress$.getValue();
    if (actionDetails?.type === 'delete') {
      this.store.dispatch(new DestroyAudience(first(actionDetails.audiences)));
      this.readDestroyAudienceResponse();
    } else if (actionDetails?.type === 'multiDelete') {
      this.store.dispatch(new DestroyAudiences(actionDetails.audiences));
      this.actions$
        .pipe(fetchOutcome(DestroyAudiences.type), take(1))
        .subscribe(
          () => {
            this.destroySuccess.show();
            this.resetSearchPayload();
            this.isLoading();
          },
          (error) => {
            const initDataLength = actionDetails.audiences.length;
            const errorMessages = error ? JSON.parse(error.message) : [];
            const successLength = initDataLength - errorMessages.length;
            if (successLength > 0) {
              this.successMessage =
                successLength + ' Lists are deleted successfully!';
              this.destroySuccess.show();
            }
            if (successLength < initDataLength) {
              this.destroyErrorHeader =
                'Unable to delete the following objects';
              this.destroyErrorMessage =
                errorMessages.toString() +
                ' can’t be deleted because they’re nested in other definitions and lists.';
              this.nestedDefs = '';
              this.destroyError.show();
            }
            this.resetSearchPayload();
            this.isLoading();
          }
        );
    }
    this.closeModal(modal);
    this.resetSearchPayload();
  }

  public closeModal(modal: ModalComponent) {
    modal.hide();
    this.actionInProgress$.next(null);
  }

  public isLoading() {
    if (this.hasAccess) {
      this.isLoader = true;
      this.search();
      if (this.filteredAudience.length) {
        this.isLoader = false;
      }
    } else {
      this.isLoader == false;
      this.filteredAudience = [];
      this.initialLoad = false;
    }
  }

  public incompleteExport(action, audience): boolean {
    return (
      !action.disabled &&
      action.display === 'Export' &&
      audience.status !== UI_STATUS_COMPLETE
    );
  }
  public isFailedStatus(action, audience): boolean {
    return (
      !action.disabled &&
      action.display === 'Deliver To...' &&
      audience.status === UI_STATUS_ERROR
    );
  }
}
