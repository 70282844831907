<div *ngIf="monitor">
    <h1  *ngIf="!isProductTypeDcdp">Job Details</h1>
    <p *ngIf="!isProductTypeDcdp">{{data?.displayName}}</p>
    <h1 *ngIf="isProductTypeDcdp">Delivery Details</h1>
    <div class="sectionSpacer">
        <div class="standalone narrow">
            <div class="standalone__content">
                <div class="label text-style-4">Activity ID:</div>
                <div class="value text-style-5">{{data?.id}}</div>
            </div>
            <div class="standalone__content">
                <div class="label text-style-4">Date Sent:</div>
                <div class="value text-style-5">{{ getDate(data?.createdDate)}}
                    <span *ngIf="data?.createdBy">by {{ data?.createdBy }}</span>
                </div>
            </div>
            <div class="standalone__content" *ngIf="!isProductTypeDcdp">
                <div class="label text-style-4">Data Universe:</div>
                <div class="value text-style-5">{{data?.dataUniverseId}}</div>
            </div>
            <div class="standalone__content" *ngIf="!isProductTypeDcdp">
                <div class="label text-style-4" *ngIf="!isProductTypeDcdp">Destination/Template:</div>
                <div class="label text-style-4" *ngIf="isProductTypeDcdp">Destination:</div>
                <div class="value text-style-5" *ngIf="!isProductTypeDcdp">{{data?.templateId}}</div>
                <div class="value text-style-5" *ngIf="isProductTypeDcdp">N/A</div>
            </div>
            <div class="standalone__content" *ngIf="!isProductTypeDcdp">
                <div class="label text-style-4">Marketing Code:</div>
                <div class="value text-style-5">{{data?.taxonomyCode}}</div>
            </div>
            <div class="standalone__content" *ngIf="isProductTypeDcdp">
                <div class="label text-style-4">Deliver To:</div>
                <div class="value text-style-5">
                    <div [class.has-bottom-space]="data.sendToTargetInfo?.connectionDisplayName">{{checkForSendToDetailsTargetType(data)}}</div>
                    <span class="text-style-muted-3">{{data.sendToTargetInfo?.connectionDisplayName ?? ''}}</span>
                </div>
            </div>
            <div class="standalone__content" *ngIf="isProductTypeDcdp">
                <div class="label text-style-4">Audience Sent:</div>
                <div  class="value text-style-5">{{data?.displayName}}</div>
            </div>
            <div class="standalone__content" *ngIf="isProductTypeDcdp">
                <div class="label text-style-4">Sent For:</div>
                <div  class="value text-style-5" *ngIf="(data?.sendToTargetType  === 'AMS') else sendToNotaAmsTargetType">
                    {{data?.sendToTargetInfo?.useCaseDisplayName}} </div>
                <ng-template #sendToNotaAmsTargetType>
                    <div class="value text-style-5">—</div>
                </ng-template>
            </div>
            <div class="standalone__content">
                <div class="label text-style-4">Status:</div>
                <div class="value">
                    <coreui-badge [subMessage]="statusTpl" [color]="(data?.status === 'Processing' || data?.status === 'FILE_GENERATED') ? 'Slate' : (data?.status === 'Error' || data?.status === 'Failed') ? 'Apple' : 'Lime'"
                        [hasIcon]="data?.status === 'Processing'">
                        <i *ngIf="data?.status === 'Processing'" coreUIIcon="spinner"></i>
                        {{data?.status}}
                    </coreui-badge>
                    <ng-template #statusTpl>{{ getDate(data?.lastModifiedDate)}}<span *ngIf="data?.lastModifiedBy"> by {{ data?.lastModifiedBy }}</span></ng-template>
                </div>
            </div>
        </div>
    </div>

    <div coreUIDivider></div>

    <div class="audience-details">
        <h2>Audience Sent</h2>
        <div class="text-style-5">
            <span>{{data?.displayName}}</span>
            <span class="pipe-divider">|</span>
            <span> {{data?.sendToTargetType === 'AMS' ? 'Paid' : 'Owned' }}</span>
        </div>
    </div>
</div>

