<coreui-section-header>
  <ng-template #header>
    <div class="header-container">
      <i coreUIIcon="bolt" class="audience-ai-icon" scale="1.5"></i>
      <span class="header-title"> Audience AI</span>
      <coreui-badge color="Pineapple">Beta</coreui-badge>
    </div>
  </ng-template>
</coreui-section-header>
<div class="container">
  <h2 class="heading">How would you like to build your audience?</h2>
  <div class="text-style-3 option">Select an option below</div>
  <div class="card-row">
    <coreui-card-container>
      <coreui-card role="button" class="Card-small" (click)="goToBuilder()">
        <div class="card-content-header">
          <div class="selector-icon"><i coreUIIcon="hierarchy"></i></div>
        </div>
        <h4 class="card-title">Visual Builder</h4>
        <span class="text-style-muted-3">
          Easily create your perfect audience with our visual drag-and-drop builder. Start crafting precise, effective
          audiences in just a few clicks.
        </span>
      </coreui-card>
    </coreui-card-container>
    <coreui-card-container>
      <coreui-card role="button" class="Card-small" (click)="goToAudienceAIChatDailog()">
        <div class="card-content-header">
          <div class="selector-icon"><i coreUIIcon="wand"></i></div>
          <coreui-badge color="Pineapple" style="position: absolute; right: 0">Beta</coreui-badge>
        </div>
        <h4 class="card-title">Audience AI</h4>
        <span class="text-style-muted-3">
          Unlock the power of AI to discover and target your ideal audience with precision. Start building smarter, more
          effective audiences today.
        </span>
      </coreui-card>
    </coreui-card-container>
  </div>
</div>