
export class AudienceAIChatRequest {
  
  chat_history: ChatMessage[] = [];
  input_message: string;
  
}

export class AudienceAIChatResponse {
  
  llm_output: string;
  chat_message: string;
  preview_data: { [key: string]: string };
  options: string[] = [];
  response_type: AudienceAIResponseType;
  genAIRequestId: string;
  query: { [key: string]: string };

}

export enum AudienceAIResponseType {
  OK = "OK",
  ERROR = "ERROR"
}

export class ChatConversation {
  
  chat_history: ChatMessage[] = [];
  
}

export class ChatMessage {
  
  role: UserType;
  content: string;
  options: string[] = [];
  genAIRequestId: string;
  feedbackRating = 0;
  isFeebackCommentGiven = false;
  query: object;

  constructor({ role, content, options = [], genAIRequestId, query }: 
    { role?: UserType; content?: string; options?: string[]; genAIRequestId?: string; query?: object } = {}) {
    this.role = role;
    this.content = content;
    this.options = options;
    this.genAIRequestId = genAIRequestId;
    this.query = query;
  }

}

export enum UserType {
  HUMAN = 'human',
  ASSISTANT = 'ai'
}