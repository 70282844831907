<div [formGroup]="formGroup">
  <coreui-table
    class="scroll-table"
    #scrollTable
    coreUIInfiniteScroll
    [scrollTarget]="scrollTable.scrollContainer"
    (scrollLimitReached)="limitReached()"
    [fixedHeader]="true"
  >
    <thead coreUITableSort>
      <tr coreUITableHeaderRow>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Delivery Name
          </coreui-table-header-column>
        </th>
        <th>
          <coreui-table-header-column [disableSort]="true">
            Sent Date
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Audience Sent
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Delivered To
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Count
          </coreui-table-header-column>
        </th>
        <!--<th scope="col" class="table-header">
          <coreui-table-multiple-header-column label="Delivery Status">
            <coreui-table-header-column coreUIMenuItem>
              Date
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>-->
        <th scope="col" class="table-header"></th>
      </tr>
    </thead>
    <tbody formArrayName="rows">
      <tr
        *ngFor="let item of filteredFeedDataList; let i = index"
        coreUITableRow
      >
        <td>
          <div class="has-bottom-space">{{ item.data?.name }}</div>
        </td>
        <td>
          <div class="text-style-muted-3">
            {{
              item.data?.lastRunTime
                ? this.datePipe.transform(
                    item.data?.lastRunTime,
                    "M/d/YY h:mm a",
                    "UTC"
                  ) + " UTC"
                : "NA"
            }}
          </div>
        </td>
        <td class="nameCol">
          <div>
            <span>{{ item?.tags?.["CAB-Audience-Display-Name"] || 'NA' }}</span>
          </div>
        </td>
        <td>
          <div
            [class.has-bottom-space]="
              item.sendToTargetInfo?.connectionDisplayName
            "
          >
           {{ item?.data?.targetConnectorSummary?.displayName || 'NA' }}
          </div>
        </td>
        <td>
          {{ item?.data?.lastRunAcceptedRecords || 'NA' }}
        </td>
        <!--<td>NA</td>-->
        <td>
          <button
            (click)="navigateTosendTo(item)"
            coreUIButton="icon"
            tabindex="0"
            *ngIf="item.tags?.['CAB-Audience-Id']"
          >
            <i scale="2.8" coreUIIcon="chevronRight"></i>
          </button>
        </td>
      </tr>
      <tr coreUITableRow *ngIf="isLoader">
        <td>
          <coreui-loading-table-column></coreui-loading-table-column>
        </td>
        <td>
          <coreui-loading-table-column></coreui-loading-table-column>
        </td>
        <td>
          <coreui-loading-table-column></coreui-loading-table-column>
        </td>
        <td>
          <coreui-loading-table-column></coreui-loading-table-column>
        </td>
        <td>
          <coreui-loading-table-column></coreui-loading-table-column>
        </td>
        <td>
          <coreui-loading-table-column></coreui-loading-table-column>
        </td>
      </tr>
    </tbody>
    <tr
      *ngIf="
        ((loadingService.isLoading$ | async) === null ||
          (loadingService.isLoading$ | async) === undefined ||
          (loadingService.isLoading$ | async) === false) &&
        !isLoader &&
        !filteredFeedDataList?.length
      "
      coreUITableRow
      [disableHover]="true"
    >
      <td colspan="8" class="empty-list">
        <coreui-graphic-message label="No Results Found" graphic="noResults">
          Sorry, we did not find any results for your search.
        </coreui-graphic-message>
      </td>
    </tr>
    <tr
      *ngIf="!filteredFeedDataList?.length && !loadingService.isLoading$"
      coreUITableRow
      [disableHover]="true"
    >
      <td colspan="8" class="empty-list">
        <coreui-graphic-message label="Nothing Here" graphic="empty">
          There are no saved definitions.
        </coreui-graphic-message>
      </td>
    </tr>
  </coreui-table>
</div>
