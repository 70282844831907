import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

function isTokenError(res: Response): boolean {
  //TODO: BE needs to give whether we need to redirect to login
  // return e.g. get(res, 'error.error.message', '').toUpperCase().includes('TOKEN');
  return res.status === 401;
}

function isAuthorizationError(res: Response): boolean {
  return res.status === 403;
}
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(@Inject('APP_CONFIG') public appConfigService: any, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {
      // TODO: need to remove okta token related code in separate PR
      // eslint-disable-next-line @typescript-eslint/naming-convention
      //Authorization: 'Bearer ' + oktaToken || '',
    };
    if(this.appConfigService.getAMSProxyURL() && req.url?.startsWith(this.appConfigService.getAMSProxyURL())) {
      headers['x-cab-context'] = this.appConfigService.getCABContextId();
    }
    req = req.clone({
      setHeaders: headers,
      withCredentials: true,
    });
    return next.handle(req).pipe(
      catchError((res) => {
        if (isTokenError(res)) {
          const params = {
            autoLogin: true
          };
          try {
            if(window.self !== window.top) {
              params['redirectURL'] = window.location.pathname;
            }
          } catch(err) {
            params['redirectURL'] = window.location.pathname;
          }
          //logout(this.storage);
          this.router.navigate(['/login'], {
            queryParams: params,
          });
        } else if(isAuthorizationError(res)) {
          this.router.navigate(['/access-denied'], {});
        }
        return observableThrowError(res);
      })
    );
   }
}
