import { Inject, Injectable } from '@angular/core';
import {
  ENV_CONFIG_MAP,
  EnvironmentConfigData,
} from '../environments/environment-config';
import { get } from 'lodash-es';
import {
  AMS_PROXY_URL_PARAM_NAME,
  CAB_API_URL_PARAM_NAME,
  DATAHUB_URL_PARAM_NAME,
  FOUNDATION_URL_PARAM_NAME,
  PRODUCT_BASE_URL_PARAM_NAME,
} from './utils';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  FEATURE_CONNECTORS_DESTINATION_PAID_EPSILON_DIGITAL,
  FEATURE_CONNECTORS_DESTINATION_PAID_OTHER,
  FEATURE_CONNECTORS_DESTINATION_TRUE_TARGET,
} from './feature-utils';
import { map, Observable, of, zip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private environmentConfigData: EnvironmentConfigData = null;
  constructor(
    @Inject('ENVIRONMENT') environment: string,
    @Inject('FEATURE_ENABLED') private featureFlagService?: any)
  {
    this.environmentConfigData = ENV_CONFIG_MAP.get(environment);
  }

  environmentValue(key) {
    if (this.environmentConfigData) {
      return get(this.environmentConfigData, key);
    } else {
      return '';
    }
  }

  isMocked(): boolean {
    return this.environmentValue('useMockData')?.toString() === 'true' || false;
  }

  getCabAPIUrl(): string {
    const urls = this.environmentValue(CAB_API_URL_PARAM_NAME);
    return get(urls, 'cab');
  }

  getDataHubAPIUrl(): string {
    return this.environmentValue(DATAHUB_URL_PARAM_NAME);
  }

  getFoundationAPIUrl(): string {
    return this.environmentValue(FOUNDATION_URL_PARAM_NAME);
  }

  getAMSProxyUrl(): string {
    return this.environmentValue(AMS_PROXY_URL_PARAM_NAME);
  }

  getProductBaseUrl(router: Router, route: ActivatedRoute | ActivatedRouteSnapshot) {
    if (router.url.split('/')[1] === 'tenant') {
      const snapshot = route instanceof ActivatedRoute ? route['snapshot'] : route;
      const tenantId =
        snapshot.params['tenantId'] ||
        snapshot.parent.params['tenantId'] ||
        snapshot.parent.parent.params['tenantId'] ||
        snapshot.parent.parent.parent.params['tenantId'] ||
        snapshot.parent.parent.parent.parent.params['tenantId'];
      return `tenant/${tenantId}/audiences`;
    } else {
      return this.environmentValue(PRODUCT_BASE_URL_PARAM_NAME);
    }
  }

  public isPaidChanelFeatureEnabled(): Observable<boolean> {
    if (!this.featureFlagService) return of(false);

    const featureConnectorsDestinationPaidEpsilonDigital =
      this.featureFlagService.isFeatureEnabled(
        FEATURE_CONNECTORS_DESTINATION_PAID_EPSILON_DIGITAL
      );
    const featureConnectorsDestinationPaidOther = this.featureFlagService.isFeatureEnabled(
      FEATURE_CONNECTORS_DESTINATION_PAID_OTHER
    );
    const featureConnectorsDestinationTrueTarget = this.featureFlagService.isFeatureEnabled(
      FEATURE_CONNECTORS_DESTINATION_TRUE_TARGET
    );
    return zip(
      featureConnectorsDestinationPaidEpsilonDigital,
      featureConnectorsDestinationPaidOther,
      featureConnectorsDestinationTrueTarget
    ).pipe(
      map((item) => {
        return item.map((item) => item).some((itemValue) => itemValue === true);
      })
    );
  }
}