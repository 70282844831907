<lib-profile-section *ngIf="audienceForm" [activeDedupeType]="audienceForm?.get('dedupeType').value" [isEdit]="isEdit"
  [audienceForm]="audienceForm" (handleInvalidDefinitionError)="triggerHandleInvalidDefinitionError()"
  (submitUpdateCount)="triggerSubmitUpdateCount()"></lib-profile-section>

<div class="details-toggle-dropdown">
  <lib-details-toggle-dropdown
    [caseInsensitiveControl]="audienceForm && audienceForm.controls['audienceQueryCaseInsensitive']"
    [caseInsensitiveDisabled]="false"
  ></lib-details-toggle-dropdown>
</div>

<div class="builder-form-container">
  <coreui-scroll-indicator>
    <div coreUIScrollIndicatorTarget>
      <div class="lib-builder-group">
        <lib-builder-group [ruleGroup]="builderService.rules" [dedupeType]="audienceForm.get('dedupeType')"
          parentExpressionIdx=0></lib-builder-group>
      </div>
    </div>
  </coreui-scroll-indicator>
</div>
