<form [formGroup]="setFormGroupForAttributes">
<coreui-notification notificationStyle="error" #failedProductDataSetIds>
    <b>Save Not Successful - Please try again later.</b>
    <br />
</coreui-notification>
<coreui-notification notificationStyle="error" #unSavedProductDataSetIds>
    <b>Error - Either you have unsaved changes or one or more fields are blank. Fix and try saving again.</b>
    <br />
</coreui-notification>
    <div class="header-container">
        <div class="header-link">
            <button coreUIButton="text-primary" withIcon="left" (click)="backToMetaData()">
                <i coreUIIcon="chevronLeft" scale="2"></i> Back to MetaData
            </button>
        </div>
        <div>
            <h1>Metadata Details</h1>
        </div>
        <div class="dataset-name" *ngIf="dataset?.displayName">
            <coreui-form-field>
                <input type="text" formControlName="displayName" coreUIInput coreUIInlineEdit [hasDelete]="false"
                    [highlight]="true" (approve)="onApproveHandler(dataset, 'displayName')" [isPersistent]="true" />
            </coreui-form-field>
        </div>
        <div class="original-name">
            <h3 class="header-title"> Original Name</h3>
            <p class="header-value">{{dataset?.productDataSetInfo.tableName}}</p>
        </div>
        <div class="divide">
            <div class="dataset-description">
                <form class="dataset-description" [formGroup]="datasetForm">
                    <coreui-form-field label="Description(optional)">
                        <textarea rows="5" coreUIInput formControlName="description"
                            placeholder="dataSet Description"></textarea>
                    </coreui-form-field>
                </form>
            </div>
            <div class="show-in-builder">
                <div>
                    <p>Show In Builder</p>
                    <p>yes</p>
                </div>
                <div class="Joins">
                    <p class="header-title">Joins</p>
                    <div class="scrollable-container">
                    <!-- Per CAB-2186 this should be removed for now -->
                    <!--div *ngFor="let join of relations" class="join-container">
                        <span><i class="icon-join" coreUIIcon="workflow"></i></span>
                        <a (click)="showMangeJoinsModal()" class="join-info"><span>{{dataset.displayName}}</span> <span
                                class="join-dot">&#8226;</span> <b>join</b>
                            <span class="join-dot">&#8226;</span> <span>{{join.targetDataSetDisplayName}}</span></a>
                    </div-->
                    </div>
                </div>
                <!--Cmmenting this piece of fucntionality for future development -->
                <!-- <div>
          <p>Show In Builder</p>
          <p>yes</p>
      </div> -->

            </div>
            <div class="modified">
                <div>
                    <p class="header-title">Last Modified</p>
                    <p class="header-value">
                        {{dataset?.lastModifiedDate | date: "yyyy-MM-dd hh:mm aa" }}
                        <span class="header-subvalue"> by {{ dataset?.lastModifiedBy }}</span>
                    </p>
                </div>
                <div>
                    <p class="header-title">Added</p>
                    <p class="header-value">
                        {{dataset?.createdDate | date: "yyyy-MM-dd hh:mm aa" }}
                        <span class="header-subvalue"> by {{ dataset?.createdBy }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="action-button-container">
            <span class="cancel"> <button coreUIButton="secondary" (click)="onCancelHandler()">Cancel</button></span>
            <span class="save"> <button coreUIButton="primary" (click)="onSaveHandler()">Save</button></span>
        </div>
        <div class="table-actions">
            <coreui-menu buttonLabel="Table Actions" buttonType="primary" menuButtonStyle="button">
                <coreui-menu-section>
                    <button type="button" coreUIMenuItem *ngFor="let item of menus">{{item.name}}</button>
                </coreui-menu-section>
            </coreui-menu>
        </div>

        <div class="attributes-table">
            <h3 class="attributes">
                <caption>Attributes</caption>
            </h3>
            <coreui-table [fixedHeader]="true" [class.sticky-table]="scrollItems" class="table tableScroll"
                coreUIInfiniteScroll #scrollTable [scrollTarget]="scrollTable.scrollContainer">
                <thead coreUITableSort>
                    <tr coreUITableHeaderRow>
                        <th scope = "col" class="table-header">
                            <coreui-table-header-column (sort)="defaultSort($event,'displayName')">
                                Display Name
                            </coreui-table-header-column>
                        </th>
                        <th scope = "col" class="table-header">
                            <coreui-table-header-column (sort)="defaultSort($event,'Description')">
                                Description
                            </coreui-table-header-column>
                        </th>
                        <th scope = "col" class="table-header">
                            <coreui-table-header-column (sort)="defaultSort($event,'productAttributeInfo.columnName')">
                                Original Name
                            </coreui-table-header-column>
                        </th>
                        <th scope = "col" class="table-header">
                            <coreui-table-header-column (sort)="defaultSort($event,'attribute.hidden')">
                                Show in Builder
                            </coreui-table-header-column>
                        </th>
                        <th scope = "col" class="table-header">
                            <coreui-table-header-column (sort)="defaultSort($event,'Ennumeration')">
                                Ennumeration
                            </coreui-table-header-column>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="loadEnumDetails">
                    <tr *ngFor="let attribute of dataset?.attributes; let i = index" coreUITableRow>
                        <td>
                            <coreui-form-field>
                                <input type="text" [formControlName]="'attribDispName_inlineEdit_'+attribute.id"
                                    coreUIInput coreUIInlineEdit [hasDelete]="false" [highlight]="true"
                                    (approve)="onApproveHandler(attribute, 'attribDispName_inlineEdit_'+attribute.id)"
                                    [isPersistent]="true" />
                            </coreui-form-field>
                        </td>
                        <td></td>
                        <td>{{attribute.productAttributeInfo.columnName}}</td>
                        <td>
                            <div class="row">
                                <coreui-form-field>
                                    <coreui-toggle [formControlName]="'builder_'+attribute.id"
                                        (valueChange)="onValueChange($event, i)"></coreui-toggle>
                                </coreui-form-field>
                            </div>
                        </td>
                        <td>
                            <coreui-form-field>
                                <coreui-select [formControlName]="'enum_'+attribute.id" placeholder="Select"
                                    [searchable]="true" #defaultSelect
                                    (valueChange)="onSelectionOfEnum($event, attribute)"
                                    (searchValueChange)="onEnumSearchChange($event, attribute.id, attribute.dataType)"
                                    (focus)="onLoadFetchListOfEnums(attribute.dataType, attribute.id)">
                                    <coreui-option *ngFor="let selectedEnum of getEnumKeys(attribute.dataType, attribute.id)"
                                        [value]="selectedEnum.id">
                                        {{selectedEnum.displayName}}</coreui-option>
                                    <span *ngIf="showSearchLimit" style="color:red">Input at least 3 characters to
                                        search.</span>
                                    <span *ngIf="!showSearchLimit && (enumSearchForAttribute.get(attribute.id)?.length === 0)"
                                        class="no-results">No results found...</span>
                                </coreui-select>
                            </coreui-form-field>
                        </td>
                    </tr>
                </tbody>
            </coreui-table>
        </div>
    </div>
</form>
<lib-manage-joins-modal
  *ngIf="(actionInProgress$ | async)?.type === 'manageJoins'"
  (closeManageJoinsModal)="closeManageJoins($event)"
  [dataset]="(actionInProgress$ | async)?.dataset"
></lib-manage-joins-modal>
