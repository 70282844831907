import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router, NavigationStart, NavigationCancel, NavigationError, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from './reducers';
import {
  FetchContextDetails,
  ResetContextDetails,
} from './context/context.actions';
import { AMS_PROXY_URL_PARAM_NAME, PRODUCT_BASE_URL_PARAM_NAME } from './utils/utils';
import { LoadingIndicatorComponent, Theme, ThemeColorService } from '@epsilon/core-ui';
import { fetchIfUnfetched, fetchOutcome } from './utils/fetch-state';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import {
  FetchDataUniverses,
  ResetDataUniverses,
} from './data-universe/data-universe.actions';
import { Actions } from '@ngrx/effects';
import { selectAllErrors } from './utils/fetch-state';
import { NotificationComponent } from '@epsilon/core-ui';
import { FetchUserDetails, FetchUserPermissions } from './user/user.actions';
import { LoadingService } from './services/loading.service';
import { CabService } from './cab.service';
import { selectFetchStates } from './utils/fetch-state';
import { selectTenantContext } from './tenant/tenant.reducer';
import { SetUnsavedChangesState } from './hasUnsavedChanges/hasUnsavedChanges.actions';
import { DiscardTypes } from './enums/discard-types';
import { selectApplicationContext } from './hasUnsavedChanges/hasUnsavedChanges.reducer';
import { UtilsService } from './utils/utilservice';
import { selectContext } from './context/context.reducer';

@UntilDestroy()
@Component({
  selector: 'lib-cab',
  templateUrl: './cab.component.html',
  styleUrls: ['./cab.component.sass'],
})
export class CabComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(LoadingIndicatorComponent, { static: true })
  loading: LoadingIndicatorComponent;
  @ViewChild('generalErrorNotification')
  generalErrorNotification: NotificationComponent;

  contextId: string;
  showDiscardChanges;
  productType: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public store: Store<AppState>,
    private actions$: Actions,
    public loadingService: LoadingService,
    private cabService: CabService,
    private cd: ChangeDetectorRef,
    private themeColorService: ThemeColorService,
    @Inject('APP_CONFIG') private appConfigService: any,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingService.isHttpRequestLoadingFullScreen$.next(true);
      }

      if (event instanceof NavigationCancel || event instanceof NavigationError || event instanceof NavigationEnd) {
        this.loadingService.isHttpRequestLoadingFullScreen$.next(false);
      }
    });
    this.store.select(selectApplicationContext)
    .pipe(filter((val) => val === 'checking'))
    .subscribe(() => {
      this.showDiscardChanges = true;
    });

    if (this.utilsService.environmentValue(PRODUCT_BASE_URL_PARAM_NAME) === 'cab-wrapper') {
      fetchIfUnfetched(this.store, new FetchUserDetails(), this);
    }

    this.store.select(selectTenantContext).subscribe(contextId => {
      if(contextId && contextId !== this.contextId) {
        this.contextId = contextId;
        this.fetchContext(this.contextId);
      }
    });

    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
    });

    if (!this.route.snapshot.params['contextId']) {
      //TODO: Need to find better way to get contextId Updated
      const urlParts = this.router.url.split('/');
      const productBaseUrl = this.utilsService.environmentValue('productBaseUrl');
      const baseUrlIndex = urlParts.findIndex(urlPath => urlPath === productBaseUrl);
      this.contextId = urlParts[baseUrlIndex + 1];
    } else {
      this.contextId = this.route.snapshot.params['contextId'];
    }
    this.fetchContext(this.contextId);
    this.store
      .select(selectFetchStates)
      .pipe(
        untilDestroyed(this),
        map(selectAllErrors),
        map((errors) => errors.filter((error)=> !(error.url?.endsWith('/v1/audience-list') && error.status === 400 && error.error?.errorDetails[0]?.errorMessage.includes('already exists')))),
        map((errors) => errors.length),
        distinctUntilChanged(),
        filter(Boolean)
      )
      .subscribe(() => this.generalErrorNotification?.show());
    if (this.route.snapshot.params['contextId']) {
      const urlParts = this.router.url.split('/');
      const productBaseUrl = this.utilsService.environmentValue('productBaseUrl');
      const baseUrlIndex = urlParts.findIndex(urlPath => urlPath === productBaseUrl);
      if(urlParts.length == baseUrlIndex + 2) {
        this.router.navigate([this.router.url, 'definition'])
      }
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetContextDetails());
    this.store.dispatch(new ResetDataUniverses());
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  fetchContext(contextId) {
    this.store.dispatch(new FetchContextDetails(contextId));
    this.actions$
      .pipe(fetchOutcome(FetchContextDetails.type), take(1))
      .subscribe(() => {
        this.appConfigService?.amsConfigDetails$.next({cabContextId: contextId, amsProxyURL: this.utilsService.environmentValue(AMS_PROXY_URL_PARAM_NAME)});
        // load first on any page
        const dataLoadingActions: any[] = [
          FetchDataUniverses,
          //FetchAudiences,
          //FetchUserPermissions
        ];
        if (this.productType !== 'DCDP') {
          dataLoadingActions.push(FetchUserPermissions);
        }
        dataLoadingActions.forEach((action) =>
          this.store.dispatch(new action(contextId))
        );
      });
  }

  onThemeToggleChange(value: boolean): void {
    this.themeColorService.setTheme(value ? Theme.Dark : Theme.Light);
  }

  onDiscardEventChanges(event) {
    if(event === DiscardTypes.DISCARD_AND_CONTINUE) {
      this.store.dispatch(new SetUnsavedChangesState('confirmed'));
    } else {
      this.store.dispatch(new SetUnsavedChangesState('pending'));
    }
    this.showDiscardChanges = false;
  }
}
