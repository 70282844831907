<coreui-modal #createActionModal [autoFocus]="true">
  <ng-template #titleTemplate>{{productType === 'DCDP' ? 'Create Audience List' : 'Create List'}}</ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal()"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <form class="Core-Form modalForm" [formGroup]="modalCreateForm">
      <div class="data-container" *ngIf="productType === 'DCDP'">
        <span><i coreUIIcon="audiences" class="audience-icon"></i>
        </span>
      <coreui-form-field class="audience-grid" [label]="(actionInProgress$ | async)?.definitions[0].displayName">
      <p class="estimated-count">Estimated count: {{ !audienceDefinition.count && !audienceDefinition.countUpdatedOn ? ' - ' : ((audienceDefinition?.idCount ?? audienceDefinition?.count?.count) | number) }} </p>
      </coreui-form-field>
      </div>
      <coreui-form-field class="list-alignment" label="{{productType === 'DCDP' ? 'Give your audience list a name' : 'Audience List Name'}}">
        <input coreUIInput maxlength="200" formControlName="audienceName" coreUITrapFocusInitial [ngClass]="{'is-error' : isAudienceListNameDuplicate }" />
        <span *ngIf="isAudienceListNameDuplicate" class="list-error-msg">{{errorMessage}}</span>
      </coreui-form-field>
      <coreui-form-field label="Do you want to limit the size of this list?"  *ngIf="productType === 'DCDP'">
        <coreui-select
          formControlName="listSizeLimit"
          (valueChange)="onSizeLimitOptionSelect($event)"
          [value]="isListSizeLimit"
          placeholder="Select"
        >
          <coreui-option *ngFor="let sizeLimitOption of sizeLimitOptions" [value]="sizeLimitOption.value">
            {{ sizeLimitOption.label }}
          </coreui-option>
        </coreui-select>
      </coreui-form-field>
      <coreui-radio-group *ngIf="isListSizeLimit === 'Yes'" orientation="vertical">
        <div class="radio-alignment">
          <coreui-form-field label=" Limit by a maximum count">
            <input type="radio" coreUIRadio value="limitByCount" name="limitByCountOrPercentage"  formControlName="limitByCountOrPercentage"/>
          </coreui-form-field>
          <coreui-form-field>
            <input coreUIInput type="number" min="1" coreUIIntegerMask formControlName="limitByCountValue" coreUITrapFocusInitial />
          </coreui-form-field>
        </div>
        <div class="radio-alignment">
          <coreui-form-field label=" Limit by a maximum percentage">
            <input type="radio" coreUIRadio value="limitByPercentage" name="limitByCountOrPercentage" formControlName="limitByCountOrPercentage"/>
          </coreui-form-field>
          <coreui-form-field>
            <input coreUIInput type="number"  min="1" coreUIIntegerMask formControlName="limitByPercentageValue" coreUITrapFocusInitial />
          </coreui-form-field>
        </div>
      </coreui-radio-group>
      <coreui-form-field label="Tags"  *ngIf="productType === 'DCDP' && audienceDefinition?.assetId && addedTags?.length">
        <coreui-select
          formControlName="assetTags"
          placeholder="Select Tag"
        >
          <coreui-option *ngFor="let assetTagsList of addedTags" [value]="assetTagsList">
            {{ assetTagsList }}
          </coreui-option>
        </coreui-select>
      </coreui-form-field>
      <lib-schedule-audience-modal #libScheduleAudienceModal *ngIf="schedulerEnabled" dataType="createList"></lib-schedule-audience-modal>
    </form>
  </ng-template> 
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal()">
      Cancel
    </button>
    <button coreUIButton (click)="createAction()" [disabled]="createListDisabled()">
      Create List
    </button>
  </ng-template>
</coreui-modal>

<coreui-notification notificationStyle="error" #errorCreatingList>
  <b>{{ errorMessage }}</b>
  <br/>
</coreui-notification>
