import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash-es';
import { Context, FetchUserDetails, LocalStorageService, utils, AppState, CabConstants } from '@epsilon-cdp/cab-lib';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LoadingService } from '../../../../cab/src/lib/services/loading.service';
import { ContextsService } from './contexts.service';

@UntilDestroy()
@Component({
  selector: 'app-contexts',
  templateUrl: './contexts.component.html',
  styleUrls: ['./contexts.component.sass'],
})
export class ContextsComponent implements OnInit {
  @ViewChild('metadataModal') public metadataModal;
  cabContexts: UntypedFormGroup;
  tableFormGroup: UntypedFormGroup;
  contexts: Context[];
  productTypes = new Set<string>(['All Products']);
  productTypeContexts: Context[];
  tenantName: string;
  tenantMetadata;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contextsService: ContextsService,
    private storage: LocalStorageService,
    private store: Store<AppState>,
    protected loadingService: LoadingService
  ) {
    this.tableFormGroup = new UntypedFormGroup({});
    this.cabContexts = new UntypedFormGroup({
      productType: new UntypedFormControl()
    });
  }

  fetchContexts() {
    this.contextsService
      .fetchCabContexts()
      .pipe(untilDestroyed(this))
      .subscribe(
        (cxts: Context[]) => {
          this.contexts = orderBy(cxts, ['displayName']);
          this.contexts.map(item => this.productTypes.add(item.productType));
          this.productTypeContexts = this.contexts;
        },
        (error) => console.log('error', error)
      );
  }

  navigateTo(ctx) {
    if(ctx.productType === CabConstants.DCDP_PRODUCT_NAME){
      this.router.navigate([
        this.contextsService.environmentValue(utils.PRODUCT_BASE_URL_PARAM_NAME),
        ctx.id,
        utils.ACTIVITY_TXT,
      ]);
    } else {
    this.router.navigate([
      this.contextsService.environmentValue(utils.PRODUCT_BASE_URL_PARAM_NAME),
      ctx.id,
      utils.DEFINITION_TXT,
    ]);
  }
  }

  ngOnInit(): void {
    this.cabContexts.get('productType').setValue('All Products');
    this.saveAccessToken();
    this.fetchContexts();
    this.store.dispatch(new FetchUserDetails());
  }

  // TODO: Need to move to handle for all requests.
  saveAccessToken() {
    const tokenValue = this.route.snapshot.queryParams['accessToken'];
    if (tokenValue) {
      this.storage.set(utils.OKTA_TOKEN, tokenValue);
    }
  }

  fetchContextsOfProductType(productType: string) {
    if (productType === 'All Products') {
      this.productTypeContexts = this.contexts;
    } else {
      this.productTypeContexts = this.contexts?.filter(ctx => ctx.productType === productType);
    }
  }

  showMetadata(context: Context) {
    this.tenantName = context.displayName;
    this.tenantMetadata = new Map(Object.entries(context.metadata));
    this.metadataModal.show();
  }

  closeMetadataModal() {
    this.metadataModal.hide();
  }
}
