import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreUIModule } from '@epsilon/core-ui';

import { AppComponent } from './app.component';
import { ContextsComponent } from './contexts/contexts.component';
import { LoginComponent } from './login/login.component';
import { NotAuthorizeComponent } from './not-authorize/not-authorize.component';
import { AppRoutingModule } from './app.routing';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { runtimeChecks } from '@epsilon-cdp/cab-lib';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { COREUI_DEFAULT_THEME, Theme } from '@epsilon/core-ui';
import { ReactiveFormsModule } from '@angular/forms';
import { RequestLoadingService } from 'projects/cab/src/lib/services/request-loading.service';
import { AppConfigService } from './app-config.service';
import { ENV_CONFIG_MAP } from 'projects/cab/src/lib/environments/environment-config';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { FeatureFlagService } from './feature-flag.service';
import { UserPermissionsService } from './user-permissions.service';
import { ErrorPageComponent } from './error-page/error-page.component';

import { AuthenticationService } from './services/authentication.service';


const ENVIRONMENT = 'ENVIRONMENT';
const environmentConfigFactoryApp = (environment, appConfigService: any) => {
  const environmentConfig = ENV_CONFIG_MAP.get(environment);
  environmentConfig.productBaseUrl = appConfigService.getProductBaseUrlForCabLib();
  return environmentConfig;
};

@NgModule({
  declarations: [
    AppComponent,
    ContextsComponent,
    LoginComponent,
    NotAuthorizeComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreUIModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot({}, {runtimeChecks}),
    StoreDevtoolsModule.instrument({maxAge: 50}), // Max age is the total number of past states the extension will store
    EffectsModule.forRoot([]),
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: COREUI_DEFAULT_THEME,
      useValue: Theme.Light
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestLoadingService,
      multi: true,
    },
    {
      provide: 'ENVIRONMENT',
      useValue: environment.domainName
    },
    {
      provide: 'APP_CONFIG',
      useExisting: AppConfigService
    },
    {
      provide: 'FEATURE_ENABLED',
      useExisting: FeatureFlagService
    },
    {
      provide: 'HTTP_CLIENT_SERVICE',
      useExisting: HttpClient
    },
    {
      provide: 'environmentConfigApp',
      useFactory: environmentConfigFactoryApp,
      deps: [ENVIRONMENT, 'APP_CONFIG'],
    },
    {
      provide: 'USER_PERMISSIONS',
      useExisting: UserPermissionsService
    },
    {
      provide: 'AUTHENTICATION_SERVICE',
      useExisting: AuthenticationService
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
