import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AudienceBuilderService} from '../audience-builder/audience-builder.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  BUILDER_TXT,
  DEFINITION_TXT,
  isDefined
} from '../utils/utils';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MAX_NAME_LENGTH } from '../audience/audience.utils';
import {DedupeType} from '../models/dedupe-type';
import {combineLatest as observableCombineLatest, Subject} from 'rxjs';
import {filter, startWith} from 'rxjs/operators';
import {find, first} from 'lodash-es';
import {AlternateKeyResponse, emptyAudienceRuleGroup} from '../audience-builder/audience-builder.models';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {FetchDataTypeOperators} from '../audience-builder/audience-builder.actions';
import { selectDedupeTypes } from '../data-universe/data-universe.reducer';
import {selectContext, selectContextId} from '../context/context.reducer';
import { SetUnsavedChangesState } from '../hasUnsavedChanges/hasUnsavedChanges.actions';
import { fetchIfUnfetched } from '../utils/fetch-state';
import { UtilsService } from '../utils/utilservice';
import { FEATURE_ALLOW_TAGS, FEATURE_GOLDEN_PROFILE } from '../utils/feature-utils';
import { DataSetType } from '../enums/data-types';
import { AssetService, AssetType, TagSearchResponse, TagService } from '@epsilon-cdp/pcm-common-lib';
import { FeatureService } from '../utils/feature-service';

@UntilDestroy()
@Component({
  selector: 'lib-audience-builder-setup',
  templateUrl: './audience-builder-setup.component.html',
  styleUrls: ['./audience-builder-setup.component.sass'],
})
export class AudienceBuilderSetupComponent implements OnInit, OnDestroy, OnChanges{
  @Input()
  audienceForm?: UntypedFormGroup;
  @Input()
  errorMessage?: string | void;
  @ViewChild('updateDedupeValueWarning')
  public updateDedupeValueWarning;
  @ViewChild('updateDedupeValueWarningDcdp')
  public updateDedupeValueWarningDcdp;
  @Output() setUpSaved = new EventEmitter();
  @Output() setUpAndContinue = new EventEmitter<boolean>();

  contextId: string;
  dataUniverseId: string;
  isEditAudience: boolean;
  alternativeKeyList:string[] = []
  private readonly ngUnsubscribe$ = new Subject<void>();
  selectDedupeTypes: DedupeType[];
  dedupeTypes: DedupeType[] = [];
  productType: string;
  audienceDefinitionId: string;
  allProfileDedupeTypesAvaialableFromServer = [];
  allProfileDedupeTypes: any = {
    allProfiles: [
      { identityType: 'CsCoreId', name: 'Customer-Specific CORE ID' },
      { identityType: 'Email', name: 'Email Address' },
      { identityType: 'AlternateKey', name: 'Alternate Key' },
    ],
    prospects: [
      { identityType: 'ProspectEmail', name: 'Email Address' },
      { identityType: 'ProspectCoreId', name: 'CORE ID' },
    ],
  };
  setDisplayName: string;
  isBuilderView:boolean;
  profile360GoldenProfileEnabled: boolean;
  alernateKeyErrorMessage: string;
  isProspectEnabled: boolean;
  addedTags: string[];
  selectedAssetTags: string[];
  protected readonly AssetType = AssetType;
  allowTagsEnabled = false;
  isTagsReadOnly= false;

  constructor(private route: ActivatedRoute, private router: Router,
              public builderService: AudienceBuilderService, public store: Store<AppState>,private utilsService: UtilsService, @Inject('FEATURE_ENABLED') private featureFlagService: any,     public tagService: TagService, private assetService: AssetService, public featureService: FeatureService) {
                this.isBuilderView = this.router.url.includes(`${BUILDER_TXT}/view`)
    this.audienceDefinitionId = this.route.snapshot.paramMap.get('definitionId');
    if (this.audienceDefinitionId) {
      this.isEditAudience = true;
    }
    observableCombineLatest([
      builderService.activeDataUniverseId$,
      store.select(selectContextId).pipe(filter(isDefined)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dataUniverseId, contextId]) => {
        this.contextId = contextId;
        this.dataUniverseId = dataUniverseId;
        fetchIfUnfetched(
          this.store,
          new FetchDataTypeOperators(this.contextId, this.dataUniverseId),
          this
        );
      });
  }

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    const audienceDefinitionId = this.route.snapshot.paramMap.get('definitionId');
    this.featureFlagService?.isFeatureEnabled(FEATURE_GOLDEN_PROFILE).subscribe((value:boolean)=>{
      this.profile360GoldenProfileEnabled= value;
    });

    if (audienceDefinitionId) {
      this.isEditAudience = true;
    }
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.productType = context?.productType;
      });
      this.audienceForm.valueChanges.subscribe(() => {
        this.store.dispatch(new SetUnsavedChangesState('pending'));
      });
    this.store.select(selectDedupeTypes(this.dataUniverseId)).subscribe((dedupeTypes) => {
      this.selectDedupeTypes = dedupeTypes;
      this.isProspectEnabled = dedupeTypes.map((item: DedupeType) => item.dataSetId).includes('Prospect');
      if (!this.audienceDefinitionId) {
        if(this.productType === 'DCDP') {
          for (const dedupeType of this.selectDedupeTypes) {
            if (!dedupeType.productIdentityInfo || (dedupeType.productIdentityInfo?.channelType === 'OWNED' && !dedupeType.productIdentityInfo?.goldenProfile && !dedupeType.productIdentityInfo?.profile)) {
              this.allProfileDedupeTypesAvaialableFromServer.push(dedupeType.identityType);
            }
          }
        }
        else {
            this.dedupeTypes = dedupeTypes;
        }
        this.setDefaultValue(this.dedupeTypes);
      } else {
        if (this.productType === 'DCDP') {
          for (const dedupeType of this.selectDedupeTypes) {
            if (!dedupeType.productIdentityInfo || (dedupeType.productIdentityInfo?.channelType === 'OWNED' && !dedupeType.productIdentityInfo?.goldenProfile && !dedupeType.productIdentityInfo?.profile)) {
              if (this.allProfileDedupeTypes.allProfiles.indexOf(dedupeType) === -1) {
                this.allProfileDedupeTypesAvaialableFromServer.push(dedupeType.identityType);
              }
            }
           }
          }
          else { 
            const selectedIdentityType = this.selectDedupeTypes?.filter((selectedDedupetype) => {
            return selectedDedupetype.identityType === this.audienceForm.get('dedupeType').value;
          });
          if (selectedIdentityType && selectedIdentityType.length > 0) {
            this.dedupeTypes.push(selectedIdentityType[0]);
          }
        } 
      }
    });

    this.audienceForm.get('channelType')?.valueChanges.pipe(startWith()).subscribe(res => {
      if (res === 'PAID') {
        this.audienceForm.get('identityType').setValue(this.getIdentityType('PAID', false, false));
      } else if (res === 'OWNED') {
        this.audienceForm.get('profileType').setValue('goldenprofile');
        this.audienceForm.get('identityType').setValue(this.getIdentityType('OWNED', true, false));
      }
    });

    this.audienceForm.get('profileType')?.valueChanges.pipe(startWith()).subscribe(res => {
      if (res === 'goldenprofile') {
        this.audienceForm.get('identityType').setValue(this.getIdentityType('OWNED', true, false));
      } else if (res === 'allprofiles') {
        this.audienceForm.get('dedupeAllProfiles').setValue(false);
        this.audienceForm.get('identityType').setValue(this.getIdentityType('OWNED', false, true));
      } else if (res === 'prospects') {
        this.audienceForm.get('dedupeAllProfiles').setValue(false);
        this.audienceForm.get('identityType').setValue(this.getIdentityType('OWNED', false, false, true));
      }
    });

    this.audienceForm.get('dedupeAllProfiles')?.valueChanges.pipe(startWith()).subscribe(() => {
      if (!this.audienceForm.get('dedupeAllProfiles').value) {
        this.audienceForm.get('identityType').setValue(this.getIdentityType('OWNED', false, true));
      } else {
        this.audienceForm.get('identityType').setValue(this.getIdentityType('OWNED', false, false));
      }
    });
    this.alternativeKeyList = [this.audienceForm.get('alternateKey').value]

    this.audienceForm.get('identityType')?.valueChanges.pipe(startWith()).subscribe(() => {
      if (this.audienceForm.get('identityType').value === 'AlternateKey') {
        const payload = {
          cabContextId: this.contextId,
          dataSetId: 'AlternateKey',
          attributeId: 'alternateKeyType'
        };
        this.builderService.fetchAlternateKeys(payload).subscribe((response: AlternateKeyResponse) => {
          this.alternativeKeyList = response?.results.map(item => item.id);
        });
      }
    });
    this.allowTagsEnabled = this.featureService.isFeatureEnabled(FEATURE_ALLOW_TAGS) && this.productType ==='DCDP';
    if(this.allowTagsEnabled){
      if(this.isEditAudience){
        this.addAssetTags();
        this.isTagsReadOnly = !this.audienceDefinitionId;
      } else {
        this.isTagsReadOnly = false;
        this.audienceForm.addControl('assetTags', new FormControl(''),  {emitEvent: false});
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['audienceForm'] && changes['audienceForm']?.currentValue) {
      this.audienceForm = changes['audienceForm'].currentValue;
    }
  }

  addAssetTags(){
    this.tagService
    .search({assetId: this.audienceDefinitionId, type: AssetType.AUDIENCE_DEFINITION}).pipe(untilDestroyed(this))
    .subscribe((res: TagSearchResponse) => {
      this.addedTags = res?.tags || [];
      this.audienceForm.addControl('assetTags', new FormControl(''),  {emitEvent: false});
      this.audienceForm.get('assetTags').setValue([...this.addedTags],  {emitEvent: false});
    });
  }

  selectedTags(tags: string[]) {
    if (tags) {
      this.selectedAssetTags = tags;
      this.audienceForm.get('assetTags').setValue([...this.selectedAssetTags]);
    }
  }

  isIdentityTypeEnabled(identityType: string) {
      return this.allProfileDedupeTypesAvaialableFromServer?.includes(identityType);
  }

  cancel() {
    this.store.dispatch(new SetUnsavedChangesState(null));
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      DEFINITION_TXT,
    ]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.store.dispatch(new SetUnsavedChangesState(null));
  }

  saveDedupeWarningChanges() {
    if (this.productType === 'DCDP') {
      this.updateDedupeValueWarningDcdp.hide();
    } else {
      this.updateDedupeValueWarning.hide();
    }
    this.builderService.audience.excludeConditions = [emptyAudienceRuleGroup('Exclude')];
    this.builderService.audience.includeConditions = [emptyAudienceRuleGroup('Include')];
    this.builderService.rules = [this.builderService.audience.includeConditions, this.builderService.audience.excludeConditions];
    this.audienceForm.get('dedupeType').setValue(this.audienceForm.get('identityType').value);
    this.setUpSaved.emit();
  }

  cancelDedupeChanges() {
    if (this.productType === 'DCDP') {
      this.updateDedupeValueWarningDcdp.hide();
    } else {
      this.updateDedupeValueWarning.hide();
    }
  }

  setDefaultValue(dedupeTypes: DedupeType[]) {
    if (!this.audienceForm.get('identityType').value) {
      if (this.productType === 'DCDP') {
        for (const dedupeType of this.selectDedupeTypes) {
          if (dedupeType.productIdentityInfo?.channelType === 'PAID') {
            this.audienceForm.get('identityType').setValue(dedupeType.identityType);
          }
        }
      } else {
        const defaultValue =
          find(dedupeTypes, {primaryIdentityType: true})?.identityType ||
          first(dedupeTypes)?.identityType;
        this.audienceForm.get('identityType').setValue(defaultValue);
      }
    }
  }

  dedupeCheck() {
    if (this.audienceForm.get('dedupeType').value && (this.audienceForm.get('dedupeType').value !== this.audienceForm.get('identityType').value)) {
      if (this.productType === 'DCDP') {
        this.updateDedupeValueWarningDcdp.show();
      } else {
        this.updateDedupeValueWarning.show();
      }
    } else {
      this.audienceForm.get('dedupeType').setValue(this.audienceForm.get('identityType').value);
      this.setUpSaved.emit();
    }
  }

  alternateKeySelectCheck() {
    if (
      this.audienceForm.get('identityType').value === 'AlternateKey' &&
      !this.audienceForm.get('alternateKey').value
    ) {
      this.alernateKeyErrorMessage = 'Alternate key is required';
      this.audienceForm.controls['alternateKey'].setErrors({ required: true });
    } else {
      this.audienceForm.get('alternateKey')?.clearValidators();
      this.audienceForm.controls['alternateKey'].setErrors(null);
    }
  }

  nameFieldErrorMessage(): string | void {
    const errors = this.audienceForm.controls['displayName'].errors;
    this.audienceForm.markAllAsTouched();
    if (!errors) {
      return;
    }
    const {required, maxlength, nameTaken, whitespace} = errors;
    if (required || whitespace) {
      return 'Required';
    }
    if (maxlength?.actualLength > maxlength?.requiredLength) {
      return `Name cannot be longer than ${MAX_NAME_LENGTH} characters.`;
    }
    if (nameTaken) {
      return 'This name is taken.';
    }
    return 'An unknown error occurred.';
  }

  getIdentityType(channelType: string, goldenProfile: boolean, profile: boolean, prospects = false): string {
    const filteredChannelDedupeTypes = this.selectDedupeTypes.filter(dedupeType => dedupeType.productIdentityInfo?.channelType === channelType);
    if (filteredChannelDedupeTypes.length === 1) {
      if (goldenProfile || profile) {
        return filteredChannelDedupeTypes[0].productIdentityInfo?.goldenProfile || filteredChannelDedupeTypes[0].productIdentityInfo?.profile ? filteredChannelDedupeTypes[0].identityType : '';
      }
      return filteredChannelDedupeTypes[0].identityType;
    } else if (filteredChannelDedupeTypes.length > 1) {
      const goldenProfileDedupeTypes = filteredChannelDedupeTypes.filter(dedupeType => dedupeType.productIdentityInfo?.goldenProfile === goldenProfile);
      if(prospects){
        const getProspects = () => {
          return goldenProfileDedupeTypes.find(item=> item.identityType === 'Prospect').identityType || ''
        }
        return goldenProfileDedupeTypes.length > 0 ? getProspects() : '';
      }

      if (goldenProfile) {
        return goldenProfileDedupeTypes.length > 0 ? goldenProfileDedupeTypes[0].identityType : '';
      } else {
        const profileDedupeTypes = goldenProfileDedupeTypes.filter(dedupeType => dedupeType.productIdentityInfo?.profile === profile || (!dedupeType.productIdentityInfo?.profile && !profile));
        const customerSpecificCoreId = find(profileDedupeTypes, { identityType: 'CsCoreId' });
        return customerSpecificCoreId?.identityType || (profileDedupeTypes.length > 0 ? profileDedupeTypes[0]?.identityType : '');
      }
    } else {
      return '';
    }
  }

  validateDefinitionNameAndHandleErrors(callback: () => void) {
    const displayName = this.audienceForm.get('displayName').value;
    if (!displayName) {
      this.errorMessage = 'Definition is required';
      return;
    }
    this.builderService
      .validateDefinitionName(displayName, DataSetType.AUDIENCE_DEFINITION, this.contextId)
      .subscribe(
        () => {
          this.audienceForm.controls['displayName'].setErrors({ duplicate: true });
          this.errorMessage = 'This name is already taken.';
        },
        (error) => {
          console.error(error);
          this.audienceForm.controls['displayName'].setErrors(null);
          this.errorMessage = '';
          callback();
        }
      );
  }
  
  validateSetUpAndContinue() {
    this.alternateKeySelectCheck();
    if (!this.audienceForm.valid) {
      this.errorMessage = this.nameFieldErrorMessage();
      return;
    }
    this.validateDefinitionNameAndHandleErrors(() => {
      this.dedupeCheck();
      this.alternateKeySelectCheck();
      this.setUpAndContinue.emit(true);
    });
  }

  validateDefinitionName() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.validateDefinitionNameAndHandleErrors(() => { });
  }
}
