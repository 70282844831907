import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalComponent, TableHeaderColumnComponent} from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import {ScheduleDetail, ScheduleDetailSearch, ScheduleType} from './schedules.models';
import { SchedulesService } from './schedules.service';
import { ActivatedRoute } from '@angular/router';
import {Audience, AudienceDefinition} from '../../../audience/audience.models';
import {SaveCampaignExtractSchedule} from '../../../campaign-extract/campaign-extract.actions';
import {SaveAudience} from '../../../audience/audience.actions';
import {SetPrebuiltAudience} from '../../../audience-builder/audience-builder.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {FEATURE_SCHEDULING} from '../../../utils/feature-utils';
import { FeatureService } from '../../../utils/feature-service';

@UntilDestroy()
@Component({
  selector: 'lib-schedules-overview',
  templateUrl: './schedules-overview.component.html',
  styleUrls: ['./schedules-overview.component.sass']
})
export class SchedulesOverviewComponent implements OnInit {
  @Input() audienceItem: AudienceDefinition;
  scheduleDetailSearch: ScheduleDetailSearch;
  schedulerEnabled: boolean;
  @ViewChild('deleteScheduleActionModal') public deleteScheduleActionModal;
  @ViewChild('editSchedulerAudienceListModal') public editSchedulerAudienceListModal;
  @ViewChild('libScheduleAudienceModal') libScheduleAudienceModal;
  public sortOption = 'Job name';
  public sortDirection = 'asc';
  public schedulesDetailForm = new UntypedFormGroup({
    actionsToggle: new UntypedFormControl(true)
  });
  deleteItemIdx: number;
  updateStatusIdx: number;
  contextId: string;
  audienceId: string;
  searchPayload: any;
  selectedScheduler: ScheduleDetail;
  modalEditSchedulerForm: UntypedFormGroup;
  constructor(private scheduleService: SchedulesService, private route: ActivatedRoute,  public store: Store<AppState>, public formBuilder: UntypedFormBuilder, public featureService: FeatureService) {
    this.modalEditSchedulerForm = this.formBuilder.group({
      audienceName: new UntypedFormControl()
    });
  }

  ngOnInit() {
    this.searchPayload = {};
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.audienceId = this.route.snapshot.paramMap.get('audienceId');
    this.schedulerEnabled = this.featureService.isFeatureEnabled(FEATURE_SCHEDULING);
    this.searchPayload = {
      entityId: this.route.snapshot.paramMap.get('definitionId'),
      limit: 10,
      offset: 0,
      scheduleTypes: [],
      sort: 'ASC',
      sortBy: ['name']
    };
    this.search();
  }

  public sortAscDesc(sortType) {
    return sortType === 'ASC' ? 'ascending' : 'descending';
  }

  public sort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.sortOption = sortOption;
    this.sortDirection = sortComponent.sortDirection;
    this.searchPayload.sort = this.sortDirection;
    this.searchPayload.sortBy = [this.sortOption];
    this.search();
  }

  public toggleActiveInactive(updatedState, index) {
    const updatedBackedState = updatedState ? 'ENABLE' : 'DISABLE';
    if(updatedBackedState !== this.scheduleDetailSearch?.scheduleDetails[index]?.scheduleState) {
      this.scheduleService.updateScheduleState(this.scheduleDetailSearch.scheduleDetails[index].cabContextId, this.scheduleDetailSearch.scheduleDetails[index].scheduleId, updatedState).subscribe(() => {
        this.search();
      });
    }
  }

  public deleteScheduleItem(idx) {
    this.deleteItemIdx = idx;
    this.deleteScheduleActionModal.show();
  }

  public deleteScheduleAction() {
    this.scheduleService.deleteSchedule(this.scheduleDetailSearch.scheduleDetails[this.deleteItemIdx].cabContextId, this.scheduleDetailSearch.scheduleDetails[this.deleteItemIdx].scheduleEntityId, this.scheduleDetailSearch.scheduleDetails[this.deleteItemIdx].scheduleId).subscribe(() => {
      //this.schedules.splice(this.deleteItemIdx, 1);
      this.deleteItemIdx = -1;
      this.search();
    });
    this.deleteScheduleActionModal.hide();
  }


  search(append?) {
    this.scheduleService.search(this.contextId, this.searchPayload).subscribe(res => {
      if(append) {
        res.scheduleDetails.forEach(scheduleDetail => {
          this.scheduleDetailSearch.scheduleDetails.push(scheduleDetail);
        });
        this.scheduleDetailSearch.hasMore = res.hasMore;
      } else {
        this.scheduleDetailSearch = { ...res };
      }
    });
  }

  public limitReached(){
    if(this.scheduleDetailSearch.hasMore){
      this.searchPayload.offset +=  10;
      this.search(true);
    }
  }

  editScheduler(item: ScheduleDetail) {
    this.selectedScheduler = item;
    if(this.selectedScheduler.scheduleType === 'AUDIENCE_LIST') {
      this.modalEditSchedulerForm.get('audienceName').setValue(this.selectedScheduler.scheduleParams.name);
      this.editSchedulerAudienceListModal.show();
    }
  }

  closeModal(modalComponent: ModalComponent) {
    modalComponent.hide();
  }

  closeExportModal() {
    this.selectedScheduler = null;
  }

  public createAction(modal: ModalComponent) {
    if (!this.modalEditSchedulerForm.valid) {
      return;
    }

    const displayName = this.modalEditSchedulerForm.get('audienceName').value.trim();
    const { id, cabContextId, query, dataUniverseId, dedupeIdentityType } =
      this.audienceItem;
    const audience = {
      displayName,
      audienceDefinitionId: id,
      cabContextId,
      dataUniverseId,
      query,
      dedupeIdentityType,
    } as Audience;

    if(this.libScheduleAudienceModal?.isFutureSchedule) {
      const scheduleDetail = new ScheduleDetail();
      if(this.selectedScheduler) {
        scheduleDetail.scheduleId = this.selectedScheduler.scheduleId;
      }
      scheduleDetail.name = this.libScheduleAudienceModal.getName();
      scheduleDetail.displayName = this.libScheduleAudienceModal.getName();
      scheduleDetail.cabContextId = cabContextId;
      scheduleDetail.scheduleEntityId = id;
      scheduleDetail.scheduleType = ScheduleType.AUDIENCE_LIST;
      scheduleDetail.scheduleParams = {
        name: displayName,
        displayName,
      };
      scheduleDetail.scheduleIntervalInfo = this.libScheduleAudienceModal.getScheduleInfo();
      this.store.dispatch(new SaveCampaignExtractSchedule(scheduleDetail));
    } else {
      this.store.dispatch(new SaveAudience(audience));
      this.store.dispatch(new SetPrebuiltAudience(null));
    }
    this.closeModal(modal);
  }

}
