import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { UtilsService } from '../../utils/utilservice';
import { AUDIENCE_AI_DAILOG_TXT, BUILDER_TXT, isDefined } from '../../utils/utils';
import { DataUniverse } from '../../data-universe/data-universe.models';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { selectActiveDataUniverse, selectDataUniverses } from '../../data-universe/data-universe.reducer';
import * as audienceBuilderActions from '../../audience-builder/audience-builder.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CountsService } from '../../counts/counts.service';
import { find } from 'lodash-es';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@UntilDestroy()
@Component({
  selector: 'lib-audience-ai-selector',
  templateUrl: './audience-ai-selector.component.html',
  styleUrls: ['./audience-ai-selector.component.sass']
})
export class AudienceAISelectorComponent implements OnInit {

  contextId: string;
  activeDataUniverse: any;
  activeDataUniverse$ = this.store.select(selectActiveDataUniverse);
  dataUniverses: DataUniverse[];
  
  constructor(private router: Router,
    private route: ActivatedRoute,
    public store: Store<AppState>,
    private utilsService: UtilsService,
    private countService: CountsService) {

  }

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    
    observableCombineLatest([
      this.store.select(selectDataUniverses),
      this.activeDataUniverse$.pipe(filter(isDefined)),
    ]).pipe(untilDestroyed(this))
      .subscribe(([dataUniverses, activeDataUniverse]) => {
        this.dataUniverses = dataUniverses;
        this.activeDataUniverse = find(dataUniverses, {
          id: activeDataUniverse.id,
        });
      });
  }

  public goToAudienceAIChatDailog(): void {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      this.activeDataUniverse?.id,
      AUDIENCE_AI_DAILOG_TXT
    ]);
  }

  public goToBuilder(): void {
    this.store.dispatch(new audienceBuilderActions.LoadPrebuiltAudience(null));
    this.countService.stopCron();
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      this.activeDataUniverse?.id,
      BUILDER_TXT,
    ]);
  }

}
