import * as CabContextFeatureUtils from "../utils/cab-context-feature-utils";
import * as FeatureUtils from "../utils/feature-utils";

export class PermissionResource {
  resourceName: string;
  resourceAccess: string[];

  constructor( resourceName, resourceAccess ) {
    this.resourceName= resourceName;
    this.resourceAccess = resourceAccess;
  }
}

export class Action {
  environmentFlagName;
  contextFlagName;
  featureFlagName;
  permissionResource;

public static readonly AUDIENCE_VIEW = new Action('','','', new PermissionResource('Audiences',['view', 'write']));
public static readonly ACTIVITY_VIEW = new Action('','','', new PermissionResource('Audiences',['view', 'write']));
public static readonly DEFINITION_VIEW = new Action('','','', new PermissionResource('Audiences',['view', 'write']));
public static readonly AUDIENCE_DEFINITION_WRITE = new Action( '','', '', new PermissionResource('Audiences',['write']));
public static readonly AUDIENCE_DELETE = new Action('','','', new PermissionResource('Audiences',['delete']));
public static readonly DEFINITION_DELETE = new Action('','','', new PermissionResource('Audiences',['delete']));
public static readonly AUDIENCE_PAID_RUN = new Action('','','', new PermissionResource('Audiences',['run-paid']));
public static readonly DEFINITION_PAID_RUN = new Action('','','', new PermissionResource('Audiences',['run-paid']));
public static readonly AUDIENCE_OWNED_RUN = new Action('','','', new PermissionResource('Audiences',['run']));
public static readonly DEFINITION_OWNED_RUN = new Action('','','', new PermissionResource('Audiences',['run']));

public static readonly AUDDEF_READ = new Action(FeatureUtils.FEATURE_AUDIENCES, '', FeatureUtils.FEATURE_AUDIENCES, null);
public static readonly AGGREGATIONS = new Action(FeatureUtils.FEATURE_AGGREGATIONS , '', FeatureUtils.FEATURE_AGGREGATIONS, null);
public static readonly NESTED_AUDIENCES = new Action(FeatureUtils.FEATURE_NESTED_AUDIENCES, '', FeatureUtils.FEATURE_NESTED_AUDIENCES, null);
public static readonly NESTED_DEFINITIONS = new Action(FeatureUtils.FEATURE_NESTED_DEFINITIONS, '', FeatureUtils.FEATURE_NESTED_DEFINITIONS, null);
public static readonly NOT_CONDITION = new Action(FeatureUtils.FEATURE_NOT_CONDITION, '', FeatureUtils.FEATURE_NOT_CONDITION, null);
public static readonly RELATIVE_DATES = new Action('', '', FeatureUtils.FEATURE_RELATIVE_DATES, null);
public static readonly SHOW_PCBC_NAV_TREE = new Action(FeatureUtils.FEATURE_SHOW_PCBC_NAV_TREE, '', FeatureUtils.FEATURE_SHOW_PCBC_NAV_TREE, null);
public static readonly EXPORT_CAMPAIGN_EXTRACTS = new Action(FeatureUtils.FEATURE_EXPORT_CAMPAIGN_EXTRACTS,'', FeatureUtils.FEATURE_EXPORT_CAMPAIGN_EXTRACTS, null);
public static readonly ATTRIBUTE_SEARCH = new Action(FeatureUtils.FEATURE_ATTRIBUTE_SEARCH, '', FeatureUtils.FEATURE_ATTRIBUTE_SEARCH, null);
public static readonly SCHEDULING = new Action(FeatureUtils.FEATURE_SCHEDULING, '',FeatureUtils.FEATURE_SCHEDULING, null);
public static readonly AUDIENCE_BUILDER_WIZARD_FLOW = new Action(FeatureUtils.FEATURE_AUDIENCE_BUILDER_WIZARD_FLOW,  '', FeatureUtils.FEATURE_AUDIENCE_BUILDER_WIZARD_FLOW, null);
public static readonly AUDIENCE_BUILDER_ENUM_SELECTION = new Action(FeatureUtils.FEATURE_AUDIENCE_BUILDER_ENUM_SELECTION, '', FeatureUtils.FEATURE_AUDIENCE_BUILDER_ENUM_SELECTION, null);
public static readonly OWNED_CHANNEL_MAPPING = new Action(FeatureUtils.FEATURE_OWNED_CHANNEL_MAPPING, '', FeatureUtils.FEATURE_OWNED_CHANNEL_MAPPING, null);
public static readonly CAB_ATTRIBUTE_ACTIONS = new Action(FeatureUtils.FEATURE_CAB_ATTRIBUTE_ACTIONS, '', FeatureUtils.FEATURE_CAB_ATTRIBUTE_ACTIONS, null);
public static readonly PAID_CONNECTIONS_INFO = new Action(FeatureUtils.FEATURE_PAID_CONNECTIONS_INFO,  '', FeatureUtils.FEATURE_PAID_CONNECTIONS_INFO, null);
public static readonly AUDIENCE_QUERY_CASE_INSENSITIVE = new Action(FeatureUtils.FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE, '', FeatureUtils.FEATURE_AUDIENCE_QUERY_CASE_INSENSITIVE, null);
public static readonly ACTIVATION_SCHEDULES = new Action(FeatureUtils.FEATURE_ACTIVATION_SCHEDULES, '', FeatureUtils.FEATURE_ACTIVATION_SCHEDULES, null);
public static readonly PROFILE_360_GOLDEN_PROFILE = new Action(FeatureUtils.FEATURE_GOLDEN_PROFILE, '', FeatureUtils.FEATURE_GOLDEN_PROFILE, null);
public static readonly CONNECTORS_DESTINATION_PAID_EPSILON_DIGITAL = new Action(FeatureUtils.FEATURE_CONNECTORS_DESTINATION_PAID_EPSILON_DIGITAL, '', FeatureUtils.FEATURE_CONNECTORS_DESTINATION_PAID_EPSILON_DIGITAL,  null);
public static readonly CONNECTORS_DESTINATION_PAID_OTHER = new Action(FeatureUtils.FEATURE_CONNECTORS_DESTINATION_PAID_OTHER, '', FeatureUtils.FEATURE_CONNECTORS_DESTINATION_PAID_OTHER , null);
public static readonly CONNECTORS_DESTINATION_TRUE_TARGET = new Action( FeatureUtils.FEATURE_CONNECTORS_DESTINATION_TRUE_TARGET, '', FeatureUtils.FEATURE_CONNECTORS_DESTINATION_TRUE_TARGET, null);

public static readonly AUDIENCE_DEFINITION_QUERY_FROM_TEXT = new Action( '', CabContextFeatureUtils.CONTEXT_AUDIENCE_DEFINITION_QUERY_FROM_TEXT, '', null);
public static readonly EXPORT_AUDIENCE = new Action( '', CabContextFeatureUtils.CONTEXT_EXPORT_AUDIENCE, '', null);
public static readonly EXTRACT_AMS_GENERATE_FILE = new Action( '', CabContextFeatureUtils.CONTEXT_EXTRACT_AMS_GENERATE_FILE, '', null);
public static readonly EXTRACT_CAMPAIGN_METADATA_ENABLED = new Action( '', CabContextFeatureUtils.CONTEXT_EXTRACT_CAMPAIGN_METADATA_ENABLED, '', null);
public static readonly EXTRACT_FROM_LIST_ENABLED = new Action( '', CabContextFeatureUtils.CONTEXT_EXTRACT_FROM_LIST_ENABLED, '', null);
public static readonly FOUNDATIONS_DOAPI_USE_NAME_AS_ID = new Action( '', CabContextFeatureUtils.CONTEXT_FOUNDATIONS_DOAPI_USE_NAME_AS_ID, '', null);
public static readonly LIST_SUPPORTED = new Action( '', CabContextFeatureUtils.CONTEXT_LIST_SUPPORTED, '', new PermissionResource('Audiences',['write']));
public static readonly SEND_NOTIFICATION_ENABLED = new Action( '', CabContextFeatureUtils.CONTEXT_SEND_NOTIFICATION_ENABLED, '', null);
public static readonly UI_USE_ADH_FOR_LIST_SEND_TO = new Action('', CabContextFeatureUtils.CONTEXT_UI_USE_ADH_FOR_LIST_SEND_TO, '', null);
public static readonly UI_USE_TAMS_FOR_ACTIVITY_LISTING = new Action('', CabContextFeatureUtils.CONTEXT_UI_USE_TAMS_FOR_ACTIVITY_LISTING, '', null);
public static readonly UI_USE_TAMS_FOR_ACTIVITY_TRACKING = new Action('', CabContextFeatureUtils.CONTEXT_UI_USE_TAMS_FOR_ACTIVITY_TRACKING, '', null);
public static readonly ALLOW_TAGS = new Action(FeatureUtils.FEATURE_ALLOW_TAGS , '', FeatureUtils.FEATURE_ALLOW_TAGS, null);

  constructor(environmentFlagName, contextFlagName, featureFlagName, permissionResource) {
    this.environmentFlagName = environmentFlagName;
    this.contextFlagName = contextFlagName;
    this.featureFlagName = featureFlagName;
    this.permissionResource = permissionResource;
  }
}