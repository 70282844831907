import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { selectDataUniverses } from '../../../data-universe/data-universe.reducer';
import { DataSet, DataSetAttribute, EnumAssociatedAttributes, EnumSet, FetchTablesBySchemaSearchPayload } from '../../admin.models';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { AdminService } from '../../admin.service';

@UntilDestroy()
@Component({
  selector: 'lib-manage-enum-attributes',
  templateUrl: './manage-enum-attributes.component.html',
  styleUrls: ['./manage-enum-attributes.component.sass']
})
export class ManageEnumAttributesComponent implements AfterViewInit, OnInit {
  @ViewChild('manageAttributes') public manageAttributes;
  @ViewChild('failedSavingAssociatedAttributes', { static: true }) public failedSavingAssociatedAttributes;
  @Input() enumSet: EnumSet = { cabContextId: '', id: '', name: '', displayName: '', dataType: 'string', description: '', version: 0 };
  @Output() closeManageAttributes = new EventEmitter<any>();
  dataUniverses: DataUniverse[];
  activeDataUniverse: DataUniverse;
  dataUniverse: DataUniverse;
  addAttributesForm: UntypedFormGroup;
  enumAssociatedAttributes: EnumAssociatedAttributes[] = [];
  dataSetArray: DataSet[];
  showSearchLimit = false;
  associationsLoadCompleted = false;
  attributes: Array<string[]> = new Array<string[]>();
  enumAttributeArrayMapKeys = [];
  enumAttributeArrayMap: Map<any, any> = new Map();
  stageAssociationsMap: Map<any, any> = new Map();
  displayDataUniverses: Map<any, any> = new Map();
  fetchTablesBySchemaSearchPayload: FetchTablesBySchemaSearchPayload = {
    cabContextId: '',
    dataUniverseId: '',
    schema: '',
    ignoreExistingDataSets: false,
    limit: 200,
    offset: 0,
    displayName: ''
  };
  errorMessage = 'Error occurred while updating attribute associations';
  toggle = [];

  constructor(
    public adminService: AdminService,
    public formBuilder: UntypedFormBuilder,
    public store: Store<AppState>
  ) {
    this.addAttributesForm = this.formBuilder.group({
      selectAttribute: new UntypedFormControl(),
      selectDataUniverse: new UntypedFormControl()
    });
  }

  ngOnInit() {
    const cabContextId = this.enumSet.cabContextId;
    this.store.select(selectDataUniverses).subscribe((dataUniverse: any) => {
      this.dataUniverses = dataUniverse.filter((item) => {
        return item.displayName !== 'All Data Universes';
      });
      if(this.dataUniverses?.length > 0) {
        this.activeDataUniverse = this.dataUniverses[0];
        this.addAttributesForm.get('selectDataUniverse').setValue(this.activeDataUniverse);
        this.dataUniverses.map(dataUniverse => this.displayDataUniverses.set(dataUniverse.id, dataUniverse.displayName));
      }
    });
    const dataUniverseId = this.activeDataUniverse?.id;
    this.adminService.fetchAllDatasets(cabContextId, dataUniverseId).subscribe((result) => {
      this.dataSetArray = result.dataSetArray;
    });
    const entityEnumId = this.enumSet.id;
    this.adminService.getEnumAttributeDetails(cabContextId, entityEnumId).subscribe((result) => {
      result.dataSetAssociations?.map(enumAttribute => {
        this.enumAttributeArrayMap.set(enumAttribute.dataSetId + ':' + enumAttribute.attributeId, enumAttribute);
      });
      this.associationsLoadCompleted = true;
    });
  }

  ngAfterViewInit() {
    this.manageAttributes.show();
  }

  get getKeys() {
    return Array.from(this.enumAttributeArrayMap?.keys());
  }

  get getSelectedDataSetAttributes() {
    return this.addAttributesForm.get('selectAttribute').value.map((attributeName: string) => {
      const stageAssociationsMapValue = this.stageAssociationsMap.get(attributeName);
      return stageAssociationsMapValue.dataSetDisplayName + '/' + stageAssociationsMapValue.attributeDisplayName;
    }).join(', ');
  }

  public get attributeItemsText(): string {
    return `${this.enumAttributeArrayMap?.size} item${this.enumAttributeArrayMap?.size !== 1 ? 's' : ''} added`;
  }

  searchAttributes(searchText: string) {
    if (searchText?.trim().length > 0 && searchText?.trim().length < 3) {
      this.showSearchLimit = true;
      this.dataSetArray = [];
      return;
    }
    this.showSearchLimit = false;
    this.fetchTablesBySchemaSearchPayload.cabContextId = this.enumSet.cabContextId;
    this.fetchTablesBySchemaSearchPayload.dataUniverseId = this.addAttributesForm.get('selectDataUniverse').value.id;
    this.fetchTablesBySchemaSearchPayload.displayName = searchText;
    if (searchText.length === 0) {
      this.adminService.fetchAllDatasets(this.enumSet.cabContextId, this.fetchTablesBySchemaSearchPayload.dataUniverseId).subscribe((result) => {
        this.dataSetArray = result.dataSetArray;
      });
    } else {
      this.adminService.fetchTablesByDataUniverse(this.fetchTablesBySchemaSearchPayload).subscribe((result) => {
        this.dataSetArray = result.dataSetArray;
      });
    }
  }

  onDataUniverseSelect(dataUniverse: DataUniverse) {
    if (dataUniverse && dataUniverse?.id !== this.activeDataUniverse?.id) {
      this.activeDataUniverse = dataUniverse;
    }
    this.adminService.fetchAllDatasets(this.enumSet?.cabContextId, dataUniverse?.id).subscribe((result) => {
      this.dataSetArray = result.dataSetArray;
    });
  }

  attributeSelect(dataset: DataSet, attributes: DataSetAttribute) {
    const associatedAttribute = {
      dataSetId: dataset.id,
      attributeId: attributes.id,
      dataUniverseId: this.addAttributesForm.get('selectDataUniverse').value.id,
      dataSetDisplayName: dataset.displayName,
      attributeDisplayName: attributes.displayName
    };
    this.stageAssociationsMap.set(dataset.id + ':' + attributes.id, associatedAttribute);
  }

  attributeDeselect(dataset: DataSet, attributes: DataSetAttribute) {
    this.stageAssociationsMap.delete(dataset.id + ':' + attributes.id);
  }

  toggleOn(index: number, datasetId: string) {
    this.toggle[index] = !this.toggle[index];
    const dataSet = this.dataSetArray.find(dataset => dataset.id === datasetId);
    if (!(dataSet?.attributes?.length > 0)) {
      this.adminService.getCabDataSet(this.enumSet.cabContextId, datasetId).subscribe((res: DataSet) => {
        dataSet.attributes = res.attributes;
      });
    }
  }

  addAttribute() {
    for(const stageAssociation of this.stageAssociationsMap.entries()) {
      if(!this.enumAttributeArrayMap.has(stageAssociation[0])) {
        this.enumAttributeArrayMap.set(stageAssociation[0], stageAssociation[1]);
      }
    }
    this.stageAssociationsMap.clear();
    this.addAttributesForm.get('selectAttribute').setValue('');
  }

  saveAttributes() {
    for (const enumAttribute of this.enumAttributeArrayMap.values()) {
      this.enumAssociatedAttributes.push(enumAttribute);
    }
    const updateAssociatedAttributesPayload = {
      cabContextId: this.enumSet.cabContextId,
      dataSetAssociations: this.enumAssociatedAttributes
    };
    this.adminService.updateAssociatedAttributes(this.enumSet.cabContextId, this.enumSet.id, updateAssociatedAttributesPayload).subscribe(res => {
      if (res.resultStatus === 'SUCCESS') {
        this.closeModal('SUCCESS', this.enumSet.displayName);
      } else {
        if (res.errorDetails) {
          this.errorMessage = res.errorDetails[0]?.errorMessage;
        }
        this.failedSavingAssociatedAttributes.show();
      }
    }, error => {
      if (error.errorDetails) {
        this.errorMessage = error.errorDetails[0]?.errorMessage;
      }
      this.failedSavingAssociatedAttributes.show();
    });
  }

  onRemoveAll() {
    this.enumAttributeArrayMap.clear();
  }

  onRemoveItem(enumAssociatedAttributeKey: string) {
    this.enumAttributeArrayMap.delete(enumAssociatedAttributeKey);
  }

  closeModal(status?: string, displayName?: string) {
    this.manageAttributes.hide();
    this.closeManageAttributes.emit({status, displayName});
  }
}
