<coreui-section-header>
  <ng-template #header>
    <div class="header-container">
      <i coreUIIcon="bolt" class="audience-ai-icon" scale="1.5"></i>
      <span class="header-title"> Audience AI</span>
      <coreui-badge color="Pineapple">Beta</coreui-badge>
    </div>
  </ng-template>
</coreui-section-header>
<form [formGroup]="audienceAIFormGroup" (ngSubmit)="submitQuery()">
  <div class="container">
    <div class="left-section">
      <div class="chat-section">
        <div #chatMessages class="chat-messages" *ngIf="chatConversations.chat_history">
          <div *ngIf="isQueryRunning && chatConversations.chat_history.length===1" class="chat-initializer">
            <coreui-illustration type="communication"></coreui-illustration>
            <span class="text-style-6">Initiating Audience AI...</span>
          </div>
          <div *ngFor="let chat of chatConversations.chat_history; let i = index">
            <div *ngIf="chat.role === 'human' && i!==0" class="user-chat">
              <div class="user-icon"><i coreUIIcon="users"></i></div>
              <div class="user-text">{{ getTransformedText(chat.content) }}</div>
            </div>
            <div *ngIf="chat.role === 'ai'" class="assistant-chat">
              <div class="assistant-icon"><i coreUIIcon="wand"></i></div>
              <div class="chat-message-container">
                <div class="assistant-text" [innerHTML]="getTransformedText(chat.content)"></div>
                <div class="assistant-options" *ngIf="chatConversations.chat_history.length === i+1">
                  <button class="option-buttons" *ngFor="let option of chat.options" type="button"
                    coreUIButton="micro-secondary" (click)="selectOption(option, i)">{{option}}</button>
                </div>
                <div *ngIf="isFeedbackMenuShown(chat.genAIRequestId)" class="feedback-container">
                  <div class="feedback-overview">
                    <span class="feedback-text text-style-2">Satisfied with generated audience query?</span>
                    <div class="feedback-icons">
                      <button [ngClass]="{ 'positive-feedback-icon': isFeedbackSelected(i, 1) }" type="button" coreUIButton="icon-large"
                        (click)="selectFeedback(1, i)"><i coreUIIcon="thumbsUp"></i></button>
                      <button [ngClass]="{ 'negative-feeback-icon': isFeedbackSelected(i, -1) }" type="button" coreUIButton="icon-large"
                        (click)="selectFeedback(-1, i)"><i coreUIIcon="thumbsDown"></i></button>
                    </div>
                  </div>
                  <div *ngIf="isCommentAreaShown(i)" class="comment-section">
                    <coreui-form-field label="Provide your comments">
                      <textarea class="comment-text-area" rows="1" coreUIInput formControlName="commentMessage"></textarea>
                      <div>
                        <button type="submit" coreUIButton="micro-secondary" (click)="submitFeedback(i)">Submit Feedback</button>
                      </div>
                    </coreui-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-input-wrapper">
          <input class="chat-input-field" coreUIInput placeholder="Type your response here..."
            formControlName="chatInput" (input)="onChatInputChange($event.target.value)" />
          <button class="chat-input-button" *ngIf="!isQueryRunning" type="submit" coreUIButton="icon"
            [disabled]="!isUserInputValid">
            <i scale="1.7" coreUIIcon="arrowUp" (onClick)="submitQuery()"></i>
          </button>
          <div class="chat-input-loader" *ngIf="isQueryRunning">
            <coreui-load-mask [active]="isQueryRunning">
            </coreui-load-mask>
          </div>
        </div>
      </div>
    </div>
    <div class="right-section">
      <div class="preview-header"><span class="h2">Audience Definition</span></div>
      <hr class="preview-divider">
      <div #previewContent class="preview-content" formGroupName="previewData">
        <div *ngFor="let key of getKeys(audienceAIFormGroup.get('previewData').value)">
          <coreui-form-field label="{{key}}">
            <div class="preview-text-area">
              {{audienceAIFormGroup.get('previewData').value[key]}}
            </div>
          </coreui-form-field>
        </div>
      </div>
      <hr class="preview-divider">
      <button class="icon-tertiary-button" coreUIButton="secondary" withIcon="left" (click)="openConfirmationPopup()">
        <i coreUIIcon="refresh"></i>Restart
      </button>
    </div>
  </div>
</form>
<lib-confirmation-popup [title]="confirmationTitle" [confirmationMessage]="customMessage"
  [confirmButtonText]="confirmText" [cancelButtonText]="cancelText" (confirmEvent)="handleConfirm()"
  (cancelEvent)="handleCancel()">
</lib-confirmation-popup>

<div>
  <coreui-notification notificationStyle="success" #toastSuccess>
    Feedback Sumitted Successfully!</coreui-notification>
</div>

<div>
  <coreui-notification notificationStyle="error" #toastError>
    Error while submitting the feedback!</coreui-notification>
</div>